import {Injectable} from '@angular/core';
import {AraHttpService} from './ara-http.service';
import {environment} from '../../../environments/environment';
import {map, Observable, of} from 'rxjs';
import {HttpParams} from '@angular/common/http';
import Request from 'src/app/shared/model/requests/request';
import Role from 'src/app/shared/model/user/role';
import RequestsForRightsManagerDto from 'src/app/shared/model/requests/requests-for-rights-manager-dto';
import GeneratedRequest from '../../shared/model/requests/generated-request.interface';
import RequestsNew from '../../shared/model/requests/requests-new';
import RequestsUpdate from '../../shared/model/requests/requests-update';
import UserDisplay from '../../shared/model/user/user-display';

@Injectable({
  providedIn: 'root'
})
export class RequestHttpService {
  private http: AraHttpService;
  userRequestObservable: Observable<RequestsNew[]>;
  rightsManagerRequestObservable: Observable<RequestsForRightsManagerDto[]>;
  userArchiveObservable: Observable<UserDisplay[]>;

  constructor(http: AraHttpService) {
    this.http = http;
  }

  public getRequestById(id: number): Observable<Request> {
    return this.http.doGet(environment.request_api_url + id);
  }

  public updateRequest(request: Request): Observable<Request> {
    return this.http.doPut(environment.request_api_url, request);
  }

  public getOpenRequests(employeeNumber: string): Observable<Request[]> {
    const httpParams = new HttpParams().set('employeeNumber', employeeNumber);
    return this.http.doGetParams(environment.request_api_url + 'open/', httpParams);
  }

  public getOpenDashboardRequests(employeeNumber: string, resetObservable?: boolean): Observable<RequestsNew[]> {
    const httpParams = new HttpParams().set('employeeNumber', employeeNumber);
    if (resetObservable) this.resetUserRequestObservable();
    if (!this.userRequestObservable) {
      this.userRequestObservable = this.http.doGetParams(environment.request_api_url + 'open-dashboard-requests/', httpParams).pipe(
        map((requests: RequestsNew[]) => {
          this.userRequestObservable = of(requests);
          return requests;
        }));
    }
    return this.userRequestObservable;
  }

  public requestRights(request: Request): Observable<void> {
    this.userRequestObservable = null;
    return this.http.doPut(environment.request_api_url + 'right/', request);
  }

  public requestRules(request: Request): Observable<void> {
    this.userRequestObservable = null;
    return this.http.doPut(environment.request_api_url + 'rule/', request);
  }

  public resetUserRequestObservable():void {
    this.userRequestObservable = null;
  }

  public resetRightsManagerRequestObservable():void {
    this.rightsManagerRequestObservable = null;
  }

  public resetUserArchiveObservable():void {
    this.userArchiveObservable = null;
  }

  public getOpenRequestsAwaitingApproval(approverEmployeeNumber: string): Observable<RequestsNew[]> {
    const httpParams = new HttpParams().set('approverEmployeeNumber', approverEmployeeNumber);
    return this.http.doGetParams(environment.request_api_url + 'open-approvals/', httpParams);
  }

  public getOpenUserRequestsAwaitingApproval(userEmployeeNumber: string): Observable<Request[]> {
    const httpParams = new HttpParams().set('userEmployeeNumber', userEmployeeNumber);
    return this.http.doGetParams(environment.request_api_url + 'open-user-approvals/', httpParams);
  }

  public getOpenRequestsAwaitingRightsManager(employeeNumber: string): Observable<Request[]> {
    const httpParams = new HttpParams().set('employeeNumber', employeeNumber);
    return this.http.doGetParams(environment.request_api_url + 'open-rights-management/', httpParams);
  }

  public getArchivedRequestsForRegion(regionId: number, resetObservable?: boolean): Observable<UserDisplay[]> {
    if (resetObservable) this.resetUserArchiveObservable();
    if(!this.userArchiveObservable) {
      this.userArchiveObservable = this.http.doGet(environment.request_api_url + 'archive-users/' + regionId).pipe(
        map((userDisplays: UserDisplay[]) => {
          this.userArchiveObservable = of(userDisplays);
          return userDisplays;
        }));
    }
    return this.userArchiveObservable;
  }

  public getMultipleOpenRequestsAwaitingRightsManager(resetObservable?: boolean): Observable<RequestsForRightsManagerDto[]> {
    if (resetObservable) this.resetRightsManagerRequestObservable();
    if (!this.rightsManagerRequestObservable) {
      this.rightsManagerRequestObservable = this.http.doGet(environment.request_api_url + 'open-requests-rights-manager/').pipe(
        map((requests: RequestsForRightsManagerDto[]) => {
          this.rightsManagerRequestObservable = of(requests);
          return requests;
        }));
    }
    return this.rightsManagerRequestObservable;
  }

  public getOpenUserRequestsAwaitingRightsManager(employeeNumber: string): Observable<Request[]> {
    const httpParams = new HttpParams().set('employeeNumber', employeeNumber);
    return this.http.doGetParams(environment.request_api_url + 'open-user-rights-management/', httpParams);
  }

  public getOpenRequestsAwaitingComplianceManager(): Observable<Request[]> {
    return this.http.doGet(environment.request_api_url + 'open-compliance-management');
  }

  public getArchivedRequestsForUserInRegion(regionId: number, qNumber): Observable<Request[]> {
    return this.http.doGet(environment.request_api_url + 'archive/' + regionId +  `/${qNumber}`);
  }

  public getMyArchivedRequests(): Observable<Request[]> {
    return this.http.doGet(environment.request_api_url + 'my-archive/');
  }

  public getCompletedRequestsForUser(): Observable<Request[]> {
    return this.http.doGet(environment.request_api_url + 'users_completed_requests/');
  }

  public  getCompletedMinifiedRequestsForApprover(): Observable<RequestsNew[]> {
    return this.http.doGet(environment.request_api_url + 'approver_completed_minified_requests/');
  }

  public  getCompletedRequestsForApprover(): Observable<Request[]> {
    return this.http.doGet(environment.request_api_url + 'approver_completed_requests/');
  }

  public  getCompletedRequestsForRightsAdmin(): Observable<RequestsForRightsManagerDto[]> {
    return this.http.doGet(environment.request_api_url + 'rights_admin_completed_requests/');
  }

  public  getCompletedUserRequestsForRightsAdmin(employeeNumber: string): Observable<Request[]> {
    const httpParams = new HttpParams().set('employeeNumber', employeeNumber);
    return this.http.doGetParams(environment.request_api_url + 'rights_admin_user_completed_requests/', httpParams);
  }

  public  getCompletedRequestsForComplianceApprover(): Observable<Request[]> {
    return this.http.doGet(environment.request_api_url + 'approver_completed_requests/');
  }

  public getPendingApplicationsForRequests(requests: Request[]): Observable<any> {
    return this.http.doPost(environment.request_api_url + 'request_pending_applications', requests.map(value => value.id));
  }

  public cancelRequest(request: Request): Observable<any>{
    return this.http.doGet(environment.request_api_url + 'cancel/' + request.id);
  }

  public submitRequests(): Observable<any> {
    this.userRequestObservable = null;
    return this.http.doGet(environment.request_api_url + 'submit/');
  }

  public submitRequestsApprover(employeeNumber: string): Observable<any> {
    const httpParams = new HttpParams().set('employeeNumber', employeeNumber);
    this.userRequestObservable = null;
    return this.http.doGetParams(environment.request_api_url + 'submit_approver/', httpParams);
  }

  public updateSelectedRequest(request: RequestsUpdate): Observable<any> {
    this.userRequestObservable = null;
    return this.http.doPut(environment.request_api_url + 'update/', request);
  }

  public deleteSelectedRequest(requestId: number): Observable<any> {
    this.userRequestObservable = null;
    return this.http.doDelete(environment.request_api_url + 'delete/' + requestId);
  }
  public submitRequest(request: GeneratedRequest): Observable<any> {
    return this.http.doPut(environment.request_api_url + 'submit/', request);
  }

  public giveBackAssignments(payload): Observable<Request[]> {
    return this.http.doPost(environment.request_api_url + 'user-assignments/', payload);
  }

  public readUserOperation(requestId: number): Promise<any> {
    const url = `${environment.request_api_url}read/${requestId}`;
    return new Promise((resolve, reject) => {
      this.http.doPost(url, requestId).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

}
