<div class="container-costom p-0" *ngIf="!intBlock">
  <div class = "main-c" *ngIf="!isEditMode">
    <div class = "row" *ngIf="isApprover">
      <div class = "col-12 mb-2">
        <div class = "small-profile-heading col-12 d-flex flex-row p-0">
          <span class="pr-4">{{'' | i18n: 'current_profile'}}</span>
          <span>{{user?.surname}}, {{user?.name}} - {{user?.qnumber}}</span>
        </div>
      </div>
    </div>
    <div class = "row">
      <div class = "col-3 mb-2">
        <button class = "green_b" ds-button (click)="giveBackUserAssignments()" >{{'' | i18n: 'give_back_all_assignments'}}</button>
        <button class = "green_b" ds-button (click)="editParametersClicked()">{{'' | i18n: 'edit_parameters'}}</button>
      </div>
      <div class = "col-9 mb-2">
        <div class = "small-heading col-12 d-flex flex-row p-0">
          <span class="pr-1">Parameter - Hauptstandort :</span>
          <span *ngIf="userDefaultLocation">{{userDefaultLocation?.name}}</span>
        </div>
      </div>
    </div>
    <div class = "row">
      <div class = "col-12 mt-0">
        <span class = "small-heading">{{'' | i18n: 'application_overview'}}</span>
      </div>
    </div>

    <div class = "row main-tab-b" *ngIf="!isEditMode">
      <div class = "col-12 tab-b">
        <button class = "green_b-tab" ds-button (click)="newBusinessRuleClicked()">{{'' | i18n: 'new_business_rule'}}</button>
        <button class = "green_b-tab" ds-button (click)="newRightClicked()" [disabled]="calculateNewSingleRightDisabled()">{{'' | i18n: 'new_single_right'}}</button>
        <button class = "green_b-tab" ds-button (click)="editRequest()" [disabled]="!showEditSelectedRequest">{{'' | i18n: 'edit_request'}}</button>
        <button class = "green_b-tab" ds-button (click)="removeRequest()" [disabled]="!showRemoveSelectedRequest">{{'' | i18n: 'remove_request'}}</button>
        <button class = "green_b-tab" ds-button (click)="deleteRequest()" [disabled]="!showCancelSelectedRequest">{{'' | i18n: 'delete'}}</button>
        <button class = "green_b-tab" ds-button (click)="refreshScreen()" >{{'' | i18n: 'refresh_screen'}}</button>
      </div>
    </div>
  </div>

<div class="ag-theme-alpine row" *ngIf="!isEditMode">
  <div class="col-12 mt-2 mb-0 d-flex justify-content-end">
    <span class="push-right">
      Page Size:
      <select [(ngModel)]="paginationSize" (change)="onPageSizeChanged($event.target.value)" [id]="paginationSize" id="page-size" class="pagination-size">
        <option [value]="size.id" *ngFor="let size of paginationSizes">{{size.id}}</option>
      </select>
    </span>
  </div>
</div>
  <div class = "col-12 my-2" *ngIf="!isEditMode">
<ag-grid-angular
  style="width: 100%; height: 550px"
  class="ag-theme-alpine"
  rowHeight="50"
  [rowData]="tableData"
  [columnDefs]="columns"
  [gridOptions]="getSelectedLanguage() == 'de' ? deLocaleOption : undefined"
  [pagination]="true"
  [paginationPageSize]="paginationSize"
  [defaultColDef]="defaultColDef"
  (firstDataRendered)="onFirstDataRendered($event)"
  [rowSelection]="rowSelection"
  (rowDoubleClicked)="rowEvents.onRowDoubleClicked($event)"
  (cellClicked)="rowEvents.onCellClicked($event)"
>
</ag-grid-angular>
</div>
<!--Submit button-->
  <div class = "row" *ngIf="canViewSubmit()">
  <div class = "mt-0" *ngIf="isUserLocationSet()">
    <button class = "submit-button" ds-button (click)="submitRequests()" [disabled]="!userDefaultLocation">{{'' | i18n: 'submit_request'}}<span class="fa fa-paper-plane ml-2"></span></button>
  </div>
  <div class = "mt-0" *ngIf="!isUserLocationSet()">
    <button class = "submit-button" ds-button (click)="submitRequests()" [ds-tooltip]="getTypeLabel('select_param_tooltip')" [disabled]="!userDefaultLocation">{{'' | i18n: 'submit_request'}}<span class="fa fa-paper-plane ml-2"></span></button>
  </div>
</div>
<!--New Business rule component-->
  <app-new-business-rule *ngIf="createBusinessRule" (hideComponent)="onHideComponent($event)" (newRequestAdded)="newRequest($event)"
                        [isApprover]="isApprover" [userRequesting]="user" [maxDateNumber]="maxDateNumber" [userExistingRequests]="userRequests"></app-new-business-rule>

  <app-new-single-rights *ngIf="createSingleRights" (hideComponent)="onHideComponent($event)" (newRequestAdded)="newRequest($event)"
                         [isApprover]="isApprover" [userRequesting]="user" [maxDateNumber]="maxDateNumber" [userExistingRequests]="userRequests"></app-new-single-rights>

  <app-edit-request *ngIf="editSelectedRequest" [selectedRequest]="selectedRequest" [action]="action" [maxDateNumber]="maxDateNumber"
                    (hideComponent)="onHideComponent($event)" (newRequestAdded)="newRequest($event)" [isApprover]="isApprover" [requestUser]="user"></app-edit-request>
<!--Edit parameters component-->
  <div class = "row" *ngIf="editParameters">
  <div class = "mt-5">
    <ds-tabs>
      <ds-tabs-label for="a">Edit Parameters</ds-tabs-label>
      <ds-tabs-content class = "row" id="a">
        <div class = "col-6">
          <div class = "row">
            <div class = "col-6">
              <ds-form-field class = "mt-2">
                <label ds-label for="Location">Region:</label>
                <ds-select
                  id="region"
                  [labellingConfig]="{ placeholder: '' | i18n: 'select' }"
                  [(ngModel)]="editParamRegionValue"
                  [options]="editParamRegionOptions"
                  (valueChange)="populateLocations()"
                ></ds-select>
              </ds-form-field>
            </div>
            <div class = "col-6">
              <ds-form-field class = "mt-2">
                <label ds-label for="Location">Haupstsandort:</label>
                <ds-select
                  id="location"
                  [labellingConfig]="{ placeholder: '' | i18n: 'select' }"
                  [(ngModel)]="editParamLocationValue"
                  [options]="editParamLocationOptions"
                  (valueChange)="editParamLocationSelected()"
                ></ds-select>
              </ds-form-field>
            </div>
            <div class = "col-6 mt-2">
              <ds-form-field>
                <label ds-label>Start Date: </label>
                <ds-datepicker [(config)]="dateConfig">
                  <input ds-date [(ngModel)]="editParamStartDate" required/>
                </ds-datepicker>
              </ds-form-field>
            </div>
          </div>
        </div>
        <div class = "col-6 cmt-20">
          <button class = "green_b_standard" (click)="editParamSaveClicked()">Save</button>
          <button class = "cancel_b_standard" (click)="editParamCancelClicked()">cancel</button>
        </div>
      </ds-tabs-content>
    </ds-tabs>
  </div>
</div>
</div>
