<div class="container-costom">
  <div class="row">
    <div class="col-md-12 mt-5">
      <h1 class="h1-heading">
        <span class="green">{{'' | i18n: 'manage_single_rights'}}</span>
      </h1>
    </div>
  </div>
  <div class="row">
    <div class="form-group c-label col-md-2 app_select">
      <label>{{'' | i18n: 'select_application'}}:</label>
      <div class = "">
        <select [(ngModel)]="selectedApplication" (change)="resetSelectedRightApplication()" style="width: 100%">
          <option disabled selected >{{'' | i18n: 'choose_application'}}:</option>
          <option *ngFor="let optionApplication of allApplications" [ngValue]="optionApplication">{{optionApplication.name}}</option>
        </select>
      </div>
    </div>
    <div class="form-group c-label col-md-2 app_select">
      <label>{{'' | i18n: 'select_category'}}:</label>
      <div class = "">
        <select [(ngModel)]="selectedApplicationRightsCategory" (change)="resetSelectedRightCategory()" (focus)="shortenOptions()" style="width: 100%" >
          <option disabled selected >Select</option>
          <option *ngFor="let appRightCat of selectedApplication?.applicationRightsCategories" [ngValue]="appRightCat">{{appRightCat.name}}</option>
        </select>
      </div>
    </div>
    <div class="form-group c-label col-md-2 app_select">
      <label>{{'' | i18n: 'select_single_right'}}:</label>
      <div class = "">
        <select [(ngModel)]="selectedRight" (change)="selectedRightChanged()" (focus)="shortenOptions()" style="width: 100%">
          <option disabled selected >Choose Right</option>
          <option *ngFor="let optionRight of selectedApplicationRightsCategory?.rights" [ngValue]="optionRight">{{optionRight.name}}</option>
        </select>

      </div>
    </div>
    <div class="form-group c-label col-md-1 app_select">
    <button type="button" class="btn-arrow-right" (click)="rightArrowClick()">
      <i class="fa fa-chevron-right"></i>
    </button>
    </div>
    <div class="col-md-5 mt-2 mb-2 push-right">
      <div class="form-group" style="margin-top: 12px;">
        <button class="btn-green-costom" (click)="clickSave()" [disabled]="!saveButtonEnabled">{{'' | i18n: 'save_single_rights'}}<em class="fa fa-angle-right"></em></button>
      </div>
    </div>
  </div>
</div>
<div class="row table_costom">
  <div class="container-costom">
    <div class="row">
      <div class="col-md-12 col-lg-12 mb-4" >
        <div class="panel-body">
          <div class="col-lg-12 panel_heading">
            <div class="row">
              <div class="col-md-12 mt-4">
                <div id="accordion-request" *ngIf="showApplicationAccordion">
                  <div class="panel" *ngFor="let accordionBusinessCategory of allBusinessCategories, index as i">
                    <div class="panel-heading">
                      <h4 class="">
                        <a href="#app-rule-{{i}}" class="accordion-toggle collapsed" data-toggle="collapse"
                           data-parent="#accordion-request"
                           aria-expanded="false"><strong>{{accordionBusinessCategory.name}}</strong></a>
                      </h4>
                    </div>
                    <div id="app-rule-{{i}}" class="panel-collapse collapse" >
                      <div class="row" *ngFor="let accordionBusinessRule of accordionBusinessCategory.businessRules">
                        <div class="col-md-12">
                          <div id="accordion-request-appcat">
                            <div class="panel">
                              <div class="panel-heading">
                                <div class = "col-lg-12" style="background-color: #ffffff">
                                  <h4 class="panel-title-inner">
                                    {{accordionBusinessRule.name}}
                                    <div class = "check-container">
                                    <label class="container" >
                                      <input [(ngModel)]="accordionBusinessRule.selected"
                                             type="checkbox" (ngModelChange)="businessRuleCheckBoxClicked(accordionBusinessRule)">
                                      <span class="checkmark"></span>
                                    </label>
                                    </div>
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

