import { Pipe, PipeTransform } from '@angular/core';
import GeneratedRequest from '../model/requests/generated-request.interface';

@Pipe({
  name: 'requestFilter'
})
export class RequestPipe implements PipeTransform {

  transform(requests: any[], category: string , type: string , rule: string , status: string ) {

    if (requests){
      return requests.filter((request) => {

        return  ((type ? request?.type === type : true) && (rule ? request?.rule === rule : true  )
          && (category ? request?.category  === category : true  )
          && (status ? request?.status === status : true  ));

      });
    }

  }
}
