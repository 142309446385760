import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {RequestHttpService} from 'src/app/service/http/request-http.service';
import Request from 'src/app/shared/model/requests/request';
import BusinessCategory from 'src/app/shared/model/business-rules/business-category';
import {DateFormat} from 'src/app/shared/commons/dateFormat';
import Right from 'src/app/shared/model/rights/right';
import {ApplicationHttpService} from 'src/app/service/http/application-http.service';
import Application from 'src/app/shared/model/applications/application';
import {ApproverHttpService} from 'src/app/service/http/approver-http.service';
import {DsTableConfig, SelectListOption} from '@bmw-ds/components';
import {RequestStatusUtilsService} from 'src/app/service/http/request-status-utils.service';
import GeneratedRequest from 'src/app/shared/model/requests/generated-request.interface';
import { RequestBuilderService } from 'src/app/service/helper-services/request-builder.service';
import { DialogService } from 'src/app/service/modal/dialog.service';
import { RequestDetailsComponent } from 'src/app/components/popup/request-details/request-details.component';
import {I18nProvider} from 'src/app/service/translations/i18n.service';
import Comment from 'src/app/shared/model/requests/comment';
import {RequestStatuses} from '../../../shared/enums/request-statuses';
import Modal from '../../../shared/model/modal';
import {MessageComponent} from '../../../components/popup/message/message.component';
import {UserHttpService} from '../../../service/http/user-http.service';

@Component({
  selector: 'app-approver-dashboard',
  templateUrl: './approver-dashboard.component.html',
  styleUrls: ['./approver-dashboard.component.scss']
})
export class ApproverDashboardComponent implements OnInit {
  private readonly REQUEST_APPROVED = 'request_approved';
  private readonly REQUEST_REJECTED = 'request_rejected';
  auth = true;
  openRequestsForUserFull: GeneratedRequest[];
  openRequestsForUserFullNoEdit: GeneratedRequest[];
  openRequestsForUserFullOriginal: GeneratedRequest[];
  openRequestsForUserFiltered: Request[];
  businessCategoryMap: Map<number, BusinessCategory> = new Map<number, BusinessCategory>();
  requestForDetailsModal: Request;
  private applicationMap: Map<number, Application> = new Map<number, Application>();
  requestsWithErroniousCommentField: Set<number> = new Set<number>();
  qNumber: number;
  typeFilterOptions: SelectListOption[] = [];
  ruleFilterOptions: SelectListOption[] = [];
  categoryFilterOptions: SelectListOption[] = [];
  statusFilterOptions: SelectListOption[] = [];
  categoryFilterValue: string;
  typeFilterValue: string;
  ruleFilterValue: string;
  statusFilterValue: string;
  config: Partial<DsTableConfig> = {
    hasStickyHeader: true,
  };
  showGranted: boolean = true;
  showDeclined: boolean = true;
  constructor(private activatedRoute: ActivatedRoute, private requestHttpService: RequestHttpService,
              private dateFormat: DateFormat,
              private applicationHttpService: ApplicationHttpService, private approverHttpService: ApproverHttpService,
              public router: Router, private requestBuilderService: RequestBuilderService, private userHttpService: UserHttpService,
              private dialog: DialogService, private requestStatusUtilsService: RequestStatusUtilsService,
              private i18nProvider: I18nProvider) {
    if (this.router.getCurrentNavigation().extras.state) {
      this.qNumber = this.router.getCurrentNavigation().extras.state.qNumber;
    }
  }

  ngOnInit(): void {
    if (this.qNumber) {
      this.initOpenRequests(this.qNumber);
    } else {
      this.router.navigate(['/approver-current-request']);
    }
  }

  private initOpenRequests(qNumber) {
    this.openRequestsForUserFullOriginal = [];
    this.openRequestsForUserFullNoEdit = [];
    this.openRequestsForUserFull = [];
    this.openRequestsForUserFiltered = [];
    this.requestHttpService.getOpenUserRequestsAwaitingApproval(qNumber).subscribe(resp => {
      this.openRequestsForUserFull = JSON.parse(JSON.stringify(resp));

      this.requestBuilderService.prepareRequest(resp).then((requests: GeneratedRequest[]) => {

        if (requests) {

          this.openRequestsForUserFull = requests;

          this.requestBuilderService.getCategoryFilterList(requests).then((options: SelectListOption[]) => {
            this.categoryFilterOptions = options;
          });

          this.requestBuilderService.getTypeFilterList(requests).then((options: SelectListOption[]) => {
            this.typeFilterOptions = options;
          });

          this.requestBuilderService.getRuleFilterList(requests).then((options: SelectListOption[]) => {
            this.ruleFilterOptions = options;
          });

          this.requestBuilderService.getStatusFilterList(requests).then((options: SelectListOption[]) => {
            this.statusFilterOptions = options;
          });

          this.filterOpenRequests();
        }
      });
    });
  }


  filterOpenRequests() {
    this.openRequestsForUserFullOriginal = this.openRequestsForUserFull;
    this.openRequestsForUserFull = [];

    this.openRequestsForUserFullOriginal.forEach(req => {
      if (this.isRequestExpringSoon(req.endDate) && req.requestStatus.name == RequestStatuses.GRANTED.toString()) {
        req.requestStatus.name = RequestStatuses.EXPIRING_SOON;
        req.status = this.requestStatusUtilsService.getStatusName(req);
      }
    });

    this.openRequestsForUserFullOriginal.forEach(req => {
      if (req.requestStatus.name == RequestStatuses.PENDING) {
        req.editable = true;
        this.openRequestsForUserFull.push(req);
      }
    });

    this.openRequestsForUserFullOriginal.forEach(req => {
      if (req.requestStatus.name == RequestStatuses.CHANGES_MADE) {
        req.editable = true;
        this.openRequestsForUserFull.push(req);
      }
    });

    this.openRequestsForUserFullOriginal.forEach(req => {
      if (req.requestStatus.name == RequestStatuses.EXPIRING_SOON) {
        req.editable = false;
        this.openRequestsForUserFull.push(req);
      }
    });

    this.openRequestsForUserFullOriginal.forEach(req => {
      if (req.requestStatus.name == RequestStatuses.GRANTED) {
        req.editable = false;
        this.openRequestsForUserFull.push(req);
      }
    });

    this.openRequestsForUserFullOriginal.forEach(req => {
      if (req.requestStatus.name == RequestStatuses.IN_REMOVAL) {
        req.editable = false;
        this.openRequestsForUserFull.push(req);
      }
    });

//    console.log(this.openRequestsForUserFull);
  }

  isRequestExpringSoon(endDate: Date | string): boolean {
    console.log(endDate);
    const currentDate = new Date();
    const requestEndDate = new Date(endDate);
    const next28Days = new Date();
    next28Days.setDate(currentDate.getDate() + 28);

    return requestEndDate >= currentDate && requestEndDate <= next28Days;
  }

  ruleOrRightRequest(openRequestReq: Request) {
    if (openRequestReq?.rightDTO) {
      return 'Single Right';
    } else {
      return 'Business Rule';
    }
  }

  getCategoryName(req: Request) {
    if (req && req.businessRuleDTO && this.businessCategoryMap && this.businessCategoryMap.size > 0
      && this.businessCategoryMap.has(req.businessRuleDTO.businessCategoryId)) {
      return this.businessCategoryMap.get(req.businessRuleDTO.businessCategoryId).name;
    }
    return '';
  }

  ruleOrRightName(req: Request) {
    if (req) {
      if (req.businessRuleDTO) {
        return req.businessRuleDTO?.name;
      }
      return req.rightDTO?.name;
    } else {
      return '';
    }
  }

  getRequestRemarks(req: Request) {
    if (req) {
      return req.comments.map(comment => comment.createdBy + ' - ' +
        this.dateFormat.getDateDisplay(comment.date) + ': \n' + comment.comment).join(', \n');
    } else {
      return '';
    }
  }

  getLocations(req: Request) {
    if (req) {
      return req.locations.map(location => location.name).join('\n');
    } else {
      return '';
    }
  }

  getStatusStyle(openRequestReq: Request) {
    return this.requestStatusUtilsService.getStatusStyle(openRequestReq);
  }

  getStatusName(openRequestReq: Request) {
    return this.requestStatusUtilsService.getStatusName(openRequestReq);
  }

  showRequestDetails(req: Request) {
    this.dialog.open(RequestDetailsComponent, { data: req });
    this.requestForDetailsModal = req;
    this.requestForDetailsModal.businessRuleDTO?.rights.sort((a, b) => a.name.localeCompare(b.name));
  }

  getRightApplication(right: Right): Application {
    if (this.applicationMap) {
      return this.applicationMap.get(right.applicationId);
    }
    return null;
  }

  getRightApplicationName(right: Right) {
    const application = this.getRightApplication(right);
    return application.name;
  }

  getRightApplicationCategoryName(right: Right): string {
    const application = this.getRightApplication(right);
    return application?.applicationRightsCategories?.find(aRC => aRC.id === right.applicationRightsCategoryId).name;
  }

  approveButtonClicked() {

    for (const request of this.openRequestsForUserFull) {
      if (!request.editable) {
        continue;
      }
      const comment = new Comment();
      if (request.selected && !request.newApproverComment){
        comment.comment = this.i18nProvider.getDescription(this.REQUEST_APPROVED);
      }
      else
      if (!request.selected && request.newApproverComment){
        comment.comment = `${request.newApproverComment} (${this.i18nProvider.getDescription(this.REQUEST_REJECTED)})`;
        request.newApproverComment = `${request.newApproverComment} (${this.i18nProvider.getDescription(this.REQUEST_REJECTED)})`;
      }
      else {
        comment.comment = request.newApproverComment;
      }
      request.comments.push(comment);
    }

    this.determineErroniousCommentFields();

    if (this.requestsWithErroniousCommentField.size === 0) {
      for (const [i, openRequest] of this.openRequestsForUserFull.entries()) {
        if (!openRequest.editable) {
          continue;
        }

        if (openRequest.selected) {
          this.approverHttpService.approveRequest(openRequest).subscribe(() => {
            this.userHttpService.resetUserObservable();
            this.workflowProcessed(i);
          });
        } else {
          // complete all applications if changes made are rejected
          if (openRequest.requestStatus.name === RequestStatuses.CHANGES_MADE){
            openRequest.applications.forEach(application => application.active = true);
          }
          this.approverHttpService.rejectRequest(openRequest).subscribe(() => this.workflowProcessed(i));
        }
      }
    }
  }

  hardReload() {
    window.location.reload();
  }

  workflowProcessed(i: number){
    const data: Modal = {
      action : 'accepted',
      message: 'workflow_was_processed'
    };

    //if (this.openRequestsForUserFull.length === i + 1){
    this.dialog.open(MessageComponent, {data}).afterClosed().subscribe(() => {
      this.hardReload();
      this.hardReload();
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate(['approver-current-request']);
    });
    //}
  }

  private determineErroniousCommentFields() {
    this.requestsWithErroniousCommentField.clear();
    let showError: boolean = true;
    for (const openRequestForUser of this.openRequestsForUserFull) {
      if (!openRequestForUser.editable) {
        continue;
      }
      //this.openRequestsForUserFull.forEach(openRequestForUser => {
      if (!openRequestForUser.selected && (!openRequestForUser.newApproverComment || openRequestForUser.newApproverComment.length === 0)) {
        this.requestsWithErroniousCommentField.add(openRequestForUser.id);
        const data: Modal = {
          action : 'write_a_comment',
          message: 'comment_required'
        };
        if (showError) {
          this.dialog.open(MessageComponent, {data});
        }
        showError = false;
      }
    };
  }

  reqRemarkStyle(id: number) {
    if (this.requestsWithErroniousCommentField.has(id)) {
      return 'comments_required';
    }
    return 'comments_not_required';
  }

  getSelectPlaceholder() {
    const placeholderText = this.i18nProvider.getDescription('select');
    return { placeholder: placeholderText };
  }

  grantSelected(req, event: boolean) {
    const declineElementId = 'decline_'+req.id;
    if (event) {
      document.getElementById(declineElementId).style.display = 'none';
    } else if (!event) {
      document.getElementById(declineElementId).style.display = '';
    }

    req.selected = event;
  }

  declineSelected(req, event: boolean) {
    const declineElementId = 'grant_'+req.id;
    if (event) {
      document.getElementById(declineElementId).style.display = 'none';
    } else if (!event) {
      document.getElementById(declineElementId).style.display = '';
    }

    req.selected = false;
  }
}
