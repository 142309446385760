<div class="container-costom p-0">
  <div class="row">
    <div class = "col-md-1 mt-5 p-0 col-width">
      <ds-list-item icon="arrow_left" [iconSize]="lg" [isHoverable]="true" (click)="backToDashboard()"></ds-list-item>
    </div>
    <div class="col-md-11 mt-5 p-0">
      <h1 class="h1-heading">
        <span class="blue">{{'' | i18n: 'archive'}}</span>
      </h1>
    </div>
  </div>
  <div class = "col-12 p-0">
    <div class="col-12 d-flex justify-content-end p-0 mb-2" *ngIf="selectedRequest">
      <button class="green_b-tab" (click)="showRequestDetails(selectedRequest)">{{'' | i18n: 'details'}}</button>
      <button class="green_b-tab mr-0" (click)="showApplicationsView(selectedRequest)"  [ds-modal-trigger-for]="tpl" >{{'' | i18n: 'overview'}}</button>
    </div>
    <ag-grid-angular
      style="width: 100%; height: 1200px"
      class="ag-theme-alpine"
      rowHeight="35"
      [gridOptions]="getSelectedLanguage() == 'de' ? deLocaleOption : undefined"
      [columnDefs]="columns"
      [pagination]="true"
      [suppressDragLeaveHidesColumns]="true"
      [paginationPageSize]="10"
      [rowData]="requests"
      [rowSelection]="rowSelection"
      (rowDoubleClicked)="rowEvents.onRowDoubleClicked($event)"
      (cellClicked)="rowEvents.onRowClicked($event)"
      (paginationChanged)="rowEvents.onPaginationChanged($event)"
      [columnDefs]="columns"
      (gridReady)="onGridReady($event)">
    </ag-grid-angular>
  </div>
</div>
<ds-modal #tpl [dimensionsConfig]="modalConfig">
  <ds-box-header>
    <span class = "modal-header-title-md">
      {{selectedRequest?.type}}
    </span>
  </ds-box-header>
  <ds-box-content>
    <app-request-info [selectedRequest]="selectedRequest" [requester]="requester"></app-request-info>
  </ds-box-content>
</ds-modal>
