import {UserRole} from '../../enums/user-role';

export default class GrantRole {
  employeeNumber: string;
  regionId: number;
  userRole: UserRole;
  rightId: number;
  applicationIDs: number[]
  grantedBy: string

  constructor(qNumber: string, regionId: number, userRole: UserRole, userRightId: number = null, applicationIDs: number[], grantedBy: string) {
    this.employeeNumber = qNumber;
    this.regionId = regionId;
    this.rightId = userRightId;
    this.userRole = userRole;
    this.applicationIDs = applicationIDs;
    this.grantedBy = grantedBy;
  }
}
