import {Component, OnInit, AfterViewInit} from '@angular/core';
import {ApplicationHttpService} from 'src/app/service/http/application-http.service';
import Application from 'src/app/shared/model/applications/application';
import {SweetAlertService} from 'src/app/service/feedback/SweetAlertService.service';
import Right from 'src/app/shared/model/rights/right';
import {BusinessCategoryHttpService} from 'src/app/service/http/business-category-http.service';
import BusinessCategory from 'src/app/shared/model/business-rules/business-category';
import RulesRight from 'src/app/shared/model/rights/rules-right';
import {BusinessRuleHttpService} from 'src/app/service/http/business-rule-http.service';
import BusinessRule from 'src/app/shared/model/business-rules/business-rule';
import ApplicationRightsCategory from 'src/app/shared/model/applications/application-rights-category';

@Component({
  selector: 'app-single-rights',
  templateUrl: './single-rights.component.html',
  styleUrls: ['./single-rights.component.scss']
})
export class SingleRightsComponent implements OnInit, AfterViewInit {
  auth = true;
  private applicationHttpService: ApplicationHttpService;
  private businessCategoryService: BusinessCategoryHttpService;
  private sweetAlertService: SweetAlertService;
  showDisabled = false;
  saveButtonEnabled = false;
  display: any;
  allApplications: Application[];
  selectedApplication: Application;
  selectedApplicationRightsCategory: ApplicationRightsCategory;
  selectedRight: Right = null;
  showApplicationAccordion: boolean;
  allBusinessCategories: BusinessCategory[];
  private businessRuleService: BusinessRuleHttpService;

  constructor(applicationHttpService: ApplicationHttpService, sweetAlertService: SweetAlertService,
              businessCategoryService: BusinessCategoryHttpService, businessRuleService: BusinessRuleHttpService) {
    this.applicationHttpService = applicationHttpService;
    this.sweetAlertService = sweetAlertService;
    this.businessCategoryService = businessCategoryService;
    this.businessRuleService = businessRuleService;
  }

  ngOnInit() {
    this.applicationHttpService.getMasterApplicationsWithRights().subscribe(applications => {
        this.allApplications = applications.sort((a, b) => a.name.localeCompare(b.name));
        this.allApplications.forEach(application => {
          application.applicationRightsCategories.sort((a, b) => a.name.localeCompare(b.name));
          application.applicationRightsCategories.forEach(appRightCat => appRightCat.rights.sort((a, b) => a.name.localeCompare(b.name)));
        });
        this.businessCategoryService.getActiveBusinessCategoriesWithRules().subscribe(businessCategories => {
          this.allBusinessCategories = businessCategories;
        });
      },
      error => this.sweetAlertService.showComError('Error retrieving applications: ' + error));

  }
  ngAfterViewInit() {
    this.shortenOptions();
  }

  shortenOptions(){
    const  options = document.querySelectorAll('option');
    options.forEach(option => {
      let optionText = option.textContent;
      if (optionText && optionText.length > 38) {
        let newOption = optionText.substring(0, 38);
        option.textContent = newOption + '...';
      }
      option.style.fontSize = '13px';
    });
  }

  selectedRightChanged() {
    this.initSelectedBusinessRules();
  }

  private initSelectedBusinessRules() {
    this.allBusinessCategories.forEach(businessCategory => {
      businessCategory.businessRules.forEach(businessRule => {
        if (this.selectedRight) {
          const selected = this.selectedRight.businessRuleIds.includes(businessRule.id);
          businessRule.selected = selected;
          businessRule.canDelete = selected;
        } else {
          businessRule.selected = false;
          businessRule.canDelete = false;
        }
      });
    });
  }

  resetSelectedRightCategory() {
    this.selectedRight = null;
    this.initSelectedBusinessRules();
  }

  resetSelectedRightApplication() {
    this.selectedApplicationRightsCategory = null;
    this.selectedRight = null;
    this.initSelectedBusinessRules();
  }

  rightArrowClick() {
    this.showApplicationAccordion = true;
    this.saveButtonEnabled = true;
  }

  clickSave() {
    const ruleRights = new RulesRight();
    const deleteRuleRights = new RulesRight();
    this.allBusinessCategories.forEach(businessCategory => {
      businessCategory.businessRules.forEach(businessRule => {
        if (businessRule.selected) {
          ruleRights.ruleIds.push(businessRule.id);
        }
        if (businessRule.delete) {
          deleteRuleRights.ruleIds.push(businessRule.id);
        }
      });
    });
    ruleRights.rightId = this.selectedRight.id;
    deleteRuleRights.rightId = this.selectedRight.id;
    if (ruleRights.ruleIds.length > 0) {
      this.businessRuleService.addRightToRules(ruleRights).subscribe(() => {
          this.deleteRuleRightsInSave(deleteRuleRights);
        },
        error => {this.sweetAlertService.showComError('Error saving selection: ' + error); });
    } else {
      this.deleteRuleRightsInSave(deleteRuleRights);
    }

  }

  private deleteRuleRightsInSave(deleteRuleRights: RulesRight) {
    if (deleteRuleRights.ruleIds.length > 0) {
      this.businessRuleService.removeRightFromRules(deleteRuleRights).subscribe(() => {
        this.reloadPage();
      }, error => {
        this.sweetAlertService.showComError('Error saving selection: ' + error);
      });
    } else {
      this.reloadPage();
    }
  }

  private reloadPage() {
    this.ngOnInit();
    this.selectedApplication = null;
    this.selectedRight = null;
    this.showApplicationAccordion = false;
    this.saveButtonEnabled = false;
  }

  businessRuleCheckBoxClicked(accordionBusinessRule: BusinessRule) {
    if (accordionBusinessRule.canDelete) {
      accordionBusinessRule.delete = !accordionBusinessRule.selected;
    }
  }
}
