import {DatePipe, formatDate, DOCUMENT} from '@angular/common';
import {Component, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {DsDatepickerConfiguration, DsTableConfig, minMaxDate, SelectListOption, tr} from '@bmw-ds/components';
import {SweetAlertService} from 'src/app/service/feedback/SweetAlertService.service';
import {RequestBuilderService} from 'src/app/service/helper-services/request-builder.service';
import {ApplicationHttpService} from 'src/app/service/http/application-http.service';
import {BusinessCategoryHttpService} from 'src/app/service/http/business-category-http.service';
import {BusinessRuleHttpService} from 'src/app/service/http/business-rule-http.service';
import {LocationHttpService} from 'src/app/service/http/location-http.service';
import {LookupHttpService} from 'src/app/service/http/lookup-http.service';
import {RegionHttpService} from 'src/app/service/http/region-http.service';
import {RequestHttpService} from 'src/app/service/http/request-http.service';
import {RequestStatusUtilsService} from 'src/app/service/http/request-status-utils.service';
import {RightHttpService} from 'src/app/service/http/right-http.service';
import {UserHttpService} from 'src/app/service/http/user-http.service';
import {DialogService} from 'src/app/service/modal/dialog.service';
import {ProfileItemStatusUtilsService} from 'src/app/service/profile-item-status-utils.service';
import {I18nProvider} from 'src/app/service/translations/i18n.service';
import {DateFormat} from 'src/app/shared/commons/dateFormat';
import {RequestStatuses} from 'src/app/shared/enums/request-statuses';
import Application from 'src/app/shared/model/applications/application';
import Region from 'src/app/shared/model/requests/region';
import BmwPerson from 'src/app/shared/model/user/bmw-person';
import User from 'src/app/shared/model/user/user';
import {
  GiveBackUserAssignmentsComponent
} from '../../components/popup/give-back-user-assignments/give-back-user-assignments.component';
import Request from 'src/app/shared/model/requests/request';
import Location from 'src/app/shared/model/requests/location';
import {ConfirmationComponent} from '../../components/popup/confirmation/confirmation.component';
import Modal from '../../shared/model/modal';
import {CellClickedEvent, ColDef, FirstDataRenderedEvent, GridOptions, RowDoubleClickedEvent} from 'ag-grid-community';
// @ts-ignore
import germanLocale from '../../../../node_modules/@bmw-ds/components/bmw-ag-grid-theme/locale.de.json';
import {RequestActions} from '../../shared/enums/actions';
import {NewRequestService} from '../../service/helper-services/new-request.service';
import RequestsNew from '../../shared/model/requests/requests-new';
import {RequestDetailsNewComponent} from '../../components/popup/request-details-new/request-details-new.component';
import LocationMinified from '../../shared/model/requests/location-min';
import RegionLocationPropsMinified from '../../shared/model/requests/region-location-props-min';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../service/auth/authentication.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit, OnDestroy {
  bmwPeopleMap: Map<string, BmwPerson> = new Map<string, BmwPerson>();
  auth = true;
  createBusinessRule = false;
  createSingleRights = false;
  editSelectedRequest = false;
  userRequests: RequestsNew [];
  selectedRequest: RequestsNew;
  editParameters = false;
  searchByQNumber: string;
  user: User;
  loggedInUser: User;
  userDefaultLocation: Location;
  selectedBusinessCategoryId: string;
  comment: string;
  selectedApplicationId: string;
  applicationOptions: SelectListOption[];
  selectedApplication: Application;
  // end new request variables
  inputErrModalIsOpen = false;
  missingInputFields = '';
  businessCategoryNames = new Map<number, string>();
  duplModalIsOpen = false;
  private saveRequestDto: Request;
  editParamRegionOptions: SelectListOption[] = [];
  editParamRegionValue: string;
  editParamLocationOptions: SelectListOption[] = [];
  editParamLocationValue: string;
  editParamSelectedLocation: Location = new Location();
  editParamStartDate: string;
  typeFilterOptions: SelectListOption[] = [];
  ruleFilterOptions: SelectListOption[] = [];
  categoryFilterOptions: SelectListOption[] = [];
  statusFilterOptions: SelectListOption[] = [];
  categoryFilterValue: string;
  typeFilterValue: string;
  ruleFilterValue: string;
  statusFilterValue: string;
  giveBackAssignmentsDisabled = false;
  cancelSelectedRequest = false;
  removeSelectedRequest = false;
  showCancelSelectedRequest = false;
  showRemoveSelectedRequest = false;
  showEditSelectedRequest = false;
  action: string;
  maxDateNumber: number;
  minRegionsLocations: RegionLocationPropsMinified[] = [];
  selectedRegion: RegionLocationPropsMinified;
  minLocations: LocationMinified[] = [];
  today: number = new Date().getDate() - 1;
  dateConfig: Partial<DsDatepickerConfiguration> = {
    disabledDatesFactory: [minMaxDate({ min: new Date(new Date().setDate(this.today))})],
    allowDirectInput: false,
    selectionMode: 'range'
  };
  tableData: RequestsNew[] = [];
  rowSelection: 'single' | 'multiple' = 'single';
  gridView = false;
  config: Partial<DsTableConfig> = {
    hasStickyHeader: true,
  };
  qNumber: string;
  isApprover: boolean = false;
  @Output() hideComponent = new EventEmitter<boolean>();
  private hostName: string;
  private allowedIntDepartment: string[] = ['FG-6-C1-12', 'FG-6-C1-12-S', 'FG-6-C1-12-N', 'FG-6-C1-12-Z', 'C1-F-DE-NL-C', 'C1-F-DE-C', 'FG-9-Z-44', 'NL-3-F-C', 'NL-2-F'];// --FG-9-Z-44';
  private userDeptCode: string;
  private alertCount: number = 0;
  intBlock: boolean = false;
  businessRulesRequests = [];

  constructor(private requestService: RequestHttpService, private sweetAlertService: SweetAlertService,
              private userHttpService: UserHttpService, private regionHttpService: RegionHttpService,
              private lookupHttpService: LookupHttpService, private locationHttpService: LocationHttpService,
              private businessCategoryHttpService: BusinessCategoryHttpService, private applicationHttpService: ApplicationHttpService,
              private requestHttpService: RequestHttpService, private dateFormat: DateFormat,
              private requestBuilderService: RequestBuilderService, private rightHttpService: RightHttpService,
              private businessRuleHttpService: BusinessRuleHttpService, private datePipe: DatePipe,
              private profileItemStatusUtils: ProfileItemStatusUtilsService, private dialog: DialogService,
              private requestStatusUtilsService: RequestStatusUtilsService, private i18nProvider: I18nProvider,
              private newRequestService: NewRequestService, public router: Router, @Inject(DOCUMENT) private document: Document,
              private authenticationService: AuthenticationService) {
      if (this.router.getCurrentNavigation().extras.state) {
        if (this.router.getCurrentNavigation().extras.state.qNumber != undefined) {
          this.qNumber = this.router.getCurrentNavigation().extras.state.qNumber;
          this.userHttpService.getManagedUser(this.qNumber).subscribe(usr => {
            this.user = usr;
            this.isApprover = true;
            this.requestService.resetUserRequestObservable();
            this.initOpenRequestsForRequester();
            this.userDefaultLocation = this.user.locations.find(location => location.defaultLocation === true);
          });
        }
      }

  }
  columns = [
    {field: 'regionName', headerName: 'Region', width: 250, filter: true, sortable: true},
    {field: 'category', headerName: this.getHeaderLabel('category'), width: 250, filter: true, sortable: true},
    {field: 'type', headerName: this.getHeaderLabel('type'), width: 200, filter: true, sortable: true},
    {field: 'rule', headerName: this.getHeaderLabel('rules_or_rights'), width: 320, filter: true, sortable: true},
    {field: 'location', headerName: this.getHeaderLabel('location'), width: 320, filter: true, sortable: true, cellRenderer: (data) => {
        if (data.data.locations.length === 1){
          return data.data.locations[0];
        } else if (data.data.locations.length > 1) {
          if (localStorage.getItem('selectedLanguage') === 'en'){
            return 'Multiple';
          }else if (localStorage.getItem('selectedLanguage') === 'de'){
            return this.i18nProvider.getDescription('requester_locations');
          }
        } else {
          return " ";
        }
      }},
    {field: 'startDate', headerName: this.getHeaderLabel('start_date'), width: 180, filter: false, sortable: true, cellRenderer: (data) => {
      return formatDate(data.value, 'dd.MM.yyyy', 'de-DE');
      }},
    {field: 'endDate', headerName: this.getHeaderLabel('end_date'), width: 180, filter: false, sortable: true, cellRenderer: (data) => {
        return formatDate(data.value, 'dd.MM.yyyy', 'de-DE');
      }},
    {field: 'status', headerName: 'Status', width: 340, filter: true, sortable: true, cellClass: params => {
        return this.getReqStatusStyle(params.data.status_enum);
      }},
  ];

  public defaultColDef: ColDef = {
    resizable: true,
  };

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    params.api.sizeColumnsToFit();
  }

  deLocaleOption: GridOptions = {
    localeTextFunc(key, defaultValue) {
      const localeDef: { [_: string]: string } = germanLocale.ds.grid;
      return defaultValue && localeDef[key] ? localeDef[key] : '';
    },
  };

  rowEvents: GridOptions = {
    onRowDoubleClicked: (event: RowDoubleClickedEvent) => this.viewRequest(event.data),
    onCellClicked: (event: CellClickedEvent) => {
      this.closeBottomTab();
      this.clickOpenRequestSelect(event.data, true);
    },
  };

  paginationSize: string | number;
  paginationSizes: any[] = [
    { id: 5},
    { id: 10},
    { id: 25},
    { id: 40},
    { id: 50},
    { id: 100}
  ];
  isEditMode: boolean = false;
  approverEmployee: any = {
    isApprover: this.isApprover,
    employeeQnumber: '',
  };

  ngOnInit() {
    this.bmwPeopleMap.clear();
    this.saveRequestDto = null;
    this.gridView = this.setGridViewFromLocalStorage();
    this.paginationSize = this.setPaginationSize();
    this.searchByQNumber = sessionStorage.getItem('searchEmployeeNumber');
    this.hostName = this.document.location.hostname;
    this.userHttpService.getLoggedInUser(false).subscribe(loggedinUser => {
      this.loggedInUser = loggedinUser;
      if (!this.qNumber) {
        this.user = loggedinUser;
        this.initOpenRequestsForRequester();
        this.userDefaultLocation = this.user.locations.find(location => location.defaultLocation === true);
      }
      this.userDeptCode = this.user.departmentCode;
      this.blockUserOnInt();
      this.calculateGiveBackAllAssignmentsDisabled();
    });
    this.regionHttpService.getRegionsLocationsPropsMinified(true)
      .subscribe(regions => {
        this.minRegionsLocations = regions;
        this.minRegionsLocations = this.minRegionsLocations.sort((a, b) => a.name.localeCompare(b.name));
      });
    this.locationHttpService.getMinifiedActiveLocations()
      .subscribe(locations => {
        this.minLocations = locations;
      });
  }

  getHeaderLabel(key: string) {
    return this.i18nProvider.getDescription(key);
  }

  getSelectedLanguage(): string {
    return localStorage.getItem('selectedLanguage') ? localStorage.getItem('selectedLanguage')  : 'en';
  }

  setTableData(): any[] {
    const dataRows: RequestsNew[] = [];
    let requestValid = true;
    if (this.userRequests) {
      this.userRequests.forEach(request => {
        request.selected = false;
        requestValid = true;
        if (this.isRequestExpringSoon(request.endDate) && request.status_enum == RequestStatuses.GRANTED.toString()) {
          request.status_enum = RequestStatuses.EXPIRING_SOON.toString()
          request.status = this.getStatusLabel(request.status_enum);
        }
        // if (request.status_enum == RequestStatuses.DRAFT.toString()){
        //   requestValid = this.isNewRequestAndExpired(request.workflowDate);
        // }

        if (requestValid) {
          dataRows.push(request);
        }
      });
    }
    this.businessRulesRequests = dataRows.filter(value => {
      return value.type_enum == 'business_rule';
    });
    return dataRows;
  }

  isRequestExpringSoon(endDate: Date | string): boolean {
    const currentDate = new Date();
    const requestEndDate = new Date(endDate);
    const next28Days = new Date();
    next28Days.setDate(currentDate.getDate() + 28);

    return requestEndDate >= currentDate && requestEndDate <= next28Days;
  }

  // isNewRequestAndExpired(reqDate: Date | string): boolean {
  //   const currentDate = new Date();
  //   const requestDate = new Date(reqDate);
  //   return currentDate < requestDate;
  // }

  isNewRequestAndExpired(reqDate: Date | string): boolean {
    const currentDate = new Date();
    const requestDate = new Date(reqDate);

    // Check if the request date is today
    if (currentDate.getFullYear() === requestDate.getFullYear() && currentDate.getMonth() === requestDate.getMonth() &&
      currentDate.getDate() === requestDate.getDate()) {
      return true;
    }

    return currentDate < requestDate;
  }

  setGridView(change: boolean) {
    this.gridView = change;
    localStorage.setItem('gridView', String(change));
    this.closeBottomTab();
  }

  setGridViewFromLocalStorage() {
    if (localStorage.getItem('gridView')) {
      if (localStorage.getItem('gridView') === 'false') {
        return false;
      } else if (localStorage.getItem('gridView') === 'true') {
        return true;
      }
    }
    return false;
  }

  onHideComponent(showComponent: boolean ){
    this.createBusinessRule = showComponent;
    this.createSingleRights = showComponent;
    this.editSelectedRequest = showComponent;
    this.isEditMode = showComponent;
  }

  newRequest(requestCreated: boolean ) {
    if (requestCreated){
      this.onHideComponent(false);
      this.initOpenRequestsForRequester();
    }
  }


  private initOpenRequestsForRequester(resetObservable?: boolean) {
    this.requestService.getOpenDashboardRequests(this.user.qnumber, resetObservable)
      .subscribe(value => {
          this.userRequests = value;
          if (value) {
            this.translateLabels();
            this.requestBuilderService.getCategoryFilterList(this.userRequests).then((options: SelectListOption[]) => {
              this.categoryFilterOptions = options;
            });
            this.requestBuilderService.getTypeFilterList(this.userRequests).then((options: SelectListOption[]) => {
              this.typeFilterOptions = options.map(type => {
                return {id : type.id, label : this.i18nProvider.getDescription(type.label)};
              });
            });
            this.requestBuilderService.getRuleFilterList(this.userRequests).then((options: SelectListOption[]) => {
              this.ruleFilterOptions = options;
            });
            this.requestBuilderService.getStatusFilterList(this.userRequests).then((options: SelectListOption[]) => {
              this.statusFilterOptions = options.map(status => {
                return {id : status.id, label : this.requestStatusUtilsService.getStatusNameByStatusEnum(status.label)};
              });
            });
          }
          this.tableData = this.setTableData();
      });
  }

  viewRequest(request: RequestsNew) {
    request.applications.sort((a, b) => a.application.localeCompare(b.application));
    this.dialog.open(RequestDetailsNewComponent, { data: request });
  }

  giveBackUserAssignments() {
    this.approverEmployee.isApprover = this.isApprover;
    this.approverEmployee.employeeQnumber = this.user.qnumber;
    this.dialog.open(GiveBackUserAssignmentsComponent, {data: this.approverEmployee}).afterClosed().subscribe(() => {
      this.closeBottomTab();
      this.initOpenRequestsForRequester(true);
    });
  }


  newBusinessRuleClicked() {
    this.closeBottomTab();
    this.createBusinessRule = true;
    this.isEditMode = true;
    this.maxDateNumber = this.user.employeeType === 'EXT' ? 1 : 3;
  }

  newRightClicked() {
    this.closeBottomTab();
    this.createSingleRights = true;
    this.isEditMode = true;
    this.maxDateNumber = this.user.employeeType === 'EXT' ? 1 : 3;
  }

  private closeBottomTab() {
    this.createSingleRights = false;
    this.createBusinessRule = false;
    this.editSelectedRequest = false;
    this.removeSelectedRequest = false;
    this.cancelSelectedRequest = false;
    this.editParameters = false;
    this.isEditMode = false;
  }



  hasActiveRuleInProfileOrOpenRequest() {
    return this.userRequests?.length > 0;
  }

  canViewSubmit() {
    return this.userRequests?.filter(openReq => openReq.status_enum === RequestStatuses.DRAFT).length > 0 && !this.isEditMode;
  }


  calculateNewSingleRightDisabled() {
    return !this.hasActiveRuleInProfileOrOpenRequest();
  }

  calculateGiveBackAllAssignmentsDisabled() {
    this.giveBackAssignmentsDisabled = !this.hasActiveRuleInProfile();
  }


  hasActiveRuleInProfile() {
    return this.user?.rules?.length > 0;
  }

  clickOpenRequestSelect(openRequest: RequestsNew, event) {
    const isChecked = event ? true : false;
    this.showRemoveSelectedRequest = false;
    this.showEditSelectedRequest = false;
    this.showCancelSelectedRequest = false;

    this.userRequests.forEach(openRequestInt => {
      if (openRequestInt.id !== openRequest.id) {
        openRequestInt.selected = false;
      }
      else {
        this.selectedRequest = openRequestInt;
        if (this.selectedRequest.status_enum === RequestStatuses.EXPIRING_SOON){
          this.showRemoveSelectedRequest = isChecked;
          this.showEditSelectedRequest = isChecked;
          this.showCancelSelectedRequest = false;

        }
        if (this.selectedRequest.status_enum === RequestStatuses.GRANTED){
          this.showRemoveSelectedRequest = isChecked;

        }
        if (this.selectedRequest.status_enum === RequestStatuses.IN_REMOVAL){
          this.showRemoveSelectedRequest = true;
          this.showEditSelectedRequest = false;
          this.showCancelSelectedRequest = false;

        }

        else if (this.selectedRequest.status_enum === RequestStatuses.DRAFT) {
          this.showEditSelectedRequest = false;
          this.showRemoveSelectedRequest = false;
          this.showCancelSelectedRequest = isChecked;

        }
      }
    });
    this.closeBottomTab();

  }

  getReqStatusStyle(status: string) {
    return this.requestStatusUtilsService.getStatusStyleByStatusName(status);
  }

  submitRequests() {
    if (!this.isApprover) {
      this.requestHttpService.submitRequests().subscribe(() => {
        this.initOpenRequestsForRequester();
      });
    } else if (this.isApprover) {
      this.requestHttpService.submitRequestsApprover(this.user.qnumber).subscribe(() => {
        this.initOpenRequestsForRequester();
      });
    }
  }

  saveRequest(saveRequestDto: Request) {
    if (saveRequestDto.rights && saveRequestDto.rights.length > 0) {
      this.requestService.requestRights(saveRequestDto).subscribe(() => {
        this.closeBottomTab();
        this.initOpenRequestsForRequester();
        this.saveRequestDto = null;
      }, error => this.sweetAlertService.showError('Error.' + error.error));
    }
    if (saveRequestDto.businessRules && saveRequestDto.businessRules.length > 0) {
      this.requestService.requestRules(saveRequestDto).subscribe(() => {
        this.closeBottomTab();
        this.initOpenRequestsForRequester();
        this.saveRequestDto = null;
      }, error => this.sweetAlertService.showError('Error.' + error.error));
    }
  }



  editParametersClicked() {
    this.closeBottomTab();
    this.editParamRegionOptions = this.minRegionsLocations.map(regions => {
      return this.toSelectListOption(regions);
    });
    this.editParamLocationOptions = this.minLocations.map(location => {
      return this.toSelectListOption(location);
    });
    this.editParameters = true;
    this.isEditMode = true;
    const defaultLocation = this.user.locations.find(userLocation => userLocation.defaultLocation);
    if (defaultLocation) {
      this.editParamLocationValue = '' + defaultLocation.id;
      this.editParamStartDate = defaultLocation.startDate;
    }
  }

  populateLocations() {
    this.selectedRegion = this.minRegionsLocations.find(region => region.id === +this.editParamRegionValue);
    this.editParamLocationOptions = this.selectedRegion.locations.map(location => {
      return this.toSelectListOption(location);
    });
  }

  editParamLocationSelected() {
    if (!this.user.locations.find(value => value.id === parseInt(this.editParamLocationValue))) {
      let newLocation = new Location();
      newLocation.id = parseInt(this.editParamLocationValue);
      this.user.locations.push(newLocation);
    }

    this.user.locations.forEach(userLocation => {
      if ('' + userLocation.id === this.editParamLocationValue) {
        this.editParamSelectedLocation = userLocation;
      } else {
        userLocation.defaultLocation = false;
      }
    });
  }

  editParamSaveClicked() {
    this.editParamSelectedLocation.defaultLocation = true;
    this.editParamSelectedLocation.startDate = this.editParamStartDate;
    this.userHttpService.updateUser(this.user).subscribe(resultantUser => {
      this.user = resultantUser;
      this.userDefaultLocation = this.user.locations.find(location => location.defaultLocation === true);
      if (!this.isApprover) {
        const tokenResponse = JSON.parse(sessionStorage.getItem(UserHttpService.TOKEN_RESPONSE));
        tokenResponse.user = resultantUser;
        sessionStorage.setItem(UserHttpService.TOKEN_RESPONSE, JSON.stringify(tokenResponse));
      }
      this.editParameters = false;
      this.isEditMode = false;
    });
  }

  editParamCancelClicked() {
    this.editParameters = false;
    this.isEditMode = false;
  }

  toSelectListOption(location: any): SelectListOption {
    return {id: '' + location.id, label: location.name};
  }

  getSelectPlaceholder() {
    const placeholderText = this.i18nProvider.getDescription('select');
    return { placeholder: placeholderText };
  }


  editRequest(){
    this.closeBottomTab();
    this.editSelectedRequest = true;
    this.action = RequestActions.EDIT_REQUEST;
    this.isEditMode = true;
    this.maxDateNumber = this.user.employeeType === 'EXT' ? 1 : 3;
  }

  removeRequest(){
    this.closeBottomTab();
    this.editSelectedRequest = true;
    this.action = RequestActions.REMOVE_REQUEST;
    this.maxDateNumber = this.user.employeeType === 'EXT' ? 1 : 3;
  }

  deleteRequest(){
    const data: Modal = {
      action : 'delete',
      message: 'delete_request_message'
    };
    this.dialog.open(ConfirmationComponent, {data}).afterClosed().subscribe(results => {
      if (results){
        this.selectedRequest.status_enum = this.newRequestService.getStatus(RequestStatuses.DELETED);
        this.requestService.deleteSelectedRequest(this.selectedRequest.id).subscribe(() => {
          this.closeBottomTab();
          this.initOpenRequestsForRequester();
          this.showCancelSelectedRequest = false;
          this.showRemoveSelectedRequest = false;
          this.showEditSelectedRequest = false;
          this.saveRequestDto = null;
          this.userRequests = this.userRequests.filter(request => request.id !== this.selectedRequest.id);
        }, error => this.sweetAlertService.showError('Error.' + error.error));
        this.userRequests = this.userRequests.filter(request => request.id !== this.selectedRequest.id);
      } });
  }

  private translateLabels() {
    this.userRequests.forEach(value => {
      if (value.type.includes('_')){
        value.type_enum = value.type;
        value.type = this.getTypeLabel(value.type);
        value.status_enum = value.status;
        value.status = this.getStatusLabel(value.status);
      }
    });
  }

  getStatusLabel(status: string) {
    return this.requestStatusUtilsService.getStatusNameByStatusEnum(status);
  }

  getTypeLabel(label: string): string {
    return this.i18nProvider.getDescription(label);
  }

  isUserLocationSet(): boolean {
    return this.userDefaultLocation != undefined;
  }

  canSubmitRequest(): boolean {
    return this.businessRulesRequests.length > 0 && this.isUserLocationSet();
  }

  refreshScreen() {
    this.initOpenRequestsForRequester(true);
  }

  setPaginationSize() {
    if (localStorage.getItem('ara_dashboard_pagination')) {
      return localStorage.getItem('ara_dashboard_pagination');
    }
    return 40;
  }

  onPageSizeChanged(size: any) {
    localStorage.setItem('ara_dashboard_pagination', size);
    this.paginationSize = size;
  }

  ngOnDestroy(): void {
    if (this.isApprover) {
      this.requestService.resetUserRequestObservable();
    }
  }

  blockUserOnInt(): void {
    if (this.hostName == 'ara-i.azurewebsites.net' || this.hostName == 'ara-t.azurewebsites.net' && this.userDeptCode) {
      if (!this.allowedIntDepartment.includes(this.userDeptCode)) {
        this.alertCount = this.alertCount + 1;
        this.tableData = [];
        if (this.alertCount == 1) alert('Kein Zugriff auf die Integration, Sie werden nun abgemeldet.');
        this.authenticationService.clearStorages();
        this.intBlock = true;
      }
    }
    this.intBlock = false;
  }
}
