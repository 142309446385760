<div class = "row">
  <div class = "col-12 mb-2 mt-1">
    <h1 class="small-heading">
      <span class="ap-title">{{'' | i18n: 'user_profile'}} : </span> {{qNumber}}
    </h1>
  </div>
</div>

<div class = "table-scroller">
  <table ds-table [(config)]="config" class="table mb-5">
    <thead ds-table-head>
    <tr>
      <th scope="col">Region</th>
      <th scope="col">{{'' | i18n: 'category'}}<br />
        <ds-form-field class = "mt-2" style="width: 92%;">
          <ds-select style="width: 92%;"
                     id="category"
                     [(ngModel)]="categoryFilterValue"
                     [labellingConfig]="getSelectPlaceholder()"
                     [options]="categoryFilterOptions"
                     [showClearAll]="true"
          ></ds-select>
        </ds-form-field>
      </th>
      <th scope="col">{{'' | i18n: 'type'}}<br />
        <ds-form-field class = "mt-2">
          <ds-select
            id="type"
            [(ngModel)]="typeFilterValue"
            [labellingConfig]="getSelectPlaceholder()"
            [options]="typeFilterOptions"
            [showClearAll]="true"
          ></ds-select>
        </ds-form-field>
      </th>
      <th scope="col">{{'' | i18n: 'rules_or_rights'}}<br />
        <ds-form-field class = "mt-2">
          <ds-select
            id="rule_Rights"
            [(ngModel)]="ruleFilterValue"
            [labellingConfig]="getSelectPlaceholder()"
            [options]="ruleFilterOptions"
            [showClearAll]="true"
          ></ds-select>
        </ds-form-field>
      </th>

      <th scope="col">{{'' | i18n: 'workflow_date'}}</th>
      <th scope="col">{{'' | i18n: 'start_date'}}</th>
      <th scope="col">{{'' | i18n: 'end_date'}}</th>
      <th width="15%">Remarks
      </th>
      <th scope="col">Status<br />
        <ds-form-field class = "mt-2">
          <ds-select
            id="status"
            [(ngModel)]="statusFilterValue"
            [labellingConfig]="getSelectPlaceholder()"
            [options]="statusFilterOptions"
            [showClearAll]="true"
          ></ds-select>
        </ds-form-field>
      </th>
      <th scope="col">{{'' | i18n: 'details'}}</th>
      <th scope="col">{{'' | i18n: 'select'}}</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let req of openRequestsForUserFull | requestFilter : categoryFilterValue  : typeFilterValue : ruleFilterValue : statusFilterValue">
      <td>{{req?.region?.name}}</td>
      <td>{{req?.category}}</td>
      <td>{{req?.type}}</td>
      <td>{{req?.rule}}</td>
      <td>{{req?.requestedDate | date: 'dd.MM.yyyy'}}</td>
      <td>{{req?.startDate | date: 'dd.MM.yyyy'}}</td>
      <td>{{req?.endDate | date: 'dd.MM.yyyy'}}</td>
      <td>
        <div *ngIf="req?.editable">
          <input class = "{{reqRemarkStyle(req.id)}}" id="remarksId" type="text" placeholder="{{'' | i18n: 'approver_remarks'}}" [(ngModel)]="req.newApproverComment"/>
        </div>
      </td>
      <td class="{{getStatusStyle(req)}}">{{req.status}}</td>
      <td (click)="showRequestDetails(req)">
        <img class="ml-3 pointer" src="../../../../assets/images/icons/details.png"/>
      </td>

      <td>
        <div *ngIf="req?.editable">
          <button *ngIf="showGranted"  ds-toggle-button id="grant_{{req?.id}}" icon="check" (checkedChange)="grantSelected(req, $event)">{{'' | i18n: 'grant'}}</button>
          <button *ngIf="showDeclined" class="cta-button" ds-toggle-button id="decline_{{req?.id}}" icon="close" (checkedChange)="declineSelected(req, $event)">{{'' | i18n: 'decline'}}</button>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
</div>
<!--Comments and approve section -->
<div class = "col-12 pl-0 mt-4 d-flex justify-content-end ">
  <button ds-button   routerLink="/approver-current-request">{{'' | i18n: 'back'}}</button>
  <button ds-button  (click)="approveButtonClicked()">{{'' | i18n: 'approve'}}</button>
</div>
