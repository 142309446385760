import {Component, OnInit} from '@angular/core';
import {UserHttpService} from 'src/app/service/http/user-http.service';
import User from 'src/app/shared/model/user/user';
import {RequestHttpService} from 'src/app/service/http/request-http.service';
import {BusinessCategoryHttpService} from 'src/app/service/http/business-category-http.service';
import {LookupHttpService} from 'src/app/service/http/lookup-http.service';
import BmwPerson from 'src/app/shared/model/user/bmw-person';
import OpenRequestForApprover from './model/open-request-for-approver';
import {NavigationExtras, Router} from '@angular/router';
import {CellClickedEvent, ColDef, FirstDataRenderedEvent, GridOptions, RowDoubleClickedEvent} from 'ag-grid-community';
import {I18nProvider} from '../../../service/translations/i18n.service';
// @ts-ignore
import germanLocale from '../../../../../node_modules/@bmw-ds/components/bmw-ag-grid-theme/locale.de.json';
import RequestsNew from '../../../shared/model/requests/requests-new';
import {formatDate} from '@angular/common';

@Component({
  selector: 'app-approver-current-request',
  templateUrl: './approver-current-request.component.html',
  styleUrls: ['./approver-current-request.component.scss']
})
export class ApproverCurrentRequestComponent implements OnInit {
  auth = true;
  private user: User;
  groupedRequests: RequestsNew[];
  columns = this.getTableColumns();
  employees: BmwPerson[];
  requests: Map<string, RequestsNew> = new Map<string, RequestsNew>();
  deLocaleOption: GridOptions = {
    localeTextFunc(key, defaultValue) {
      const localeDef: { [_: string]: string } = germanLocale.ds.grid;
      return defaultValue && localeDef[key] ? localeDef[key] : '';
    },
  };
  rowEvents: GridOptions = {
    onRowDoubleClicked: (event: RowDoubleClickedEvent) => this.openApprovalDashboard(event.data),
    onCellClicked: (event: CellClickedEvent) => {
    },
  };
  paginationSize: string | number;
  paginationSizes: any[] = [
    { id: 5},
    { id: 10},
    { id: 25},
    { id: 40},
    { id: 50},
    { id: 100}
  ];

  public defaultColDef: ColDef = {
    resizable: true,
  };

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    params.api.sizeColumnsToFit();
  }
  constructor(private userHttpService: UserHttpService, private requestService: RequestHttpService,
              private businessCategoryHttpService: BusinessCategoryHttpService, private lookupHttpService: LookupHttpService,
              public router: Router, private i18nProvider: I18nProvider) {
  }

  ngOnInit(): void {
    this.userHttpService.getLoggedInUser(false).subscribe(loggedinUser => {
      this.user = loggedinUser;
      this.initOpenRequestsForApprover();
      this.paginationSize = this.setPaginationSize();
    });
  }

  private initOpenRequestsForApprover() {
    this.requestService.getOpenRequestsAwaitingApproval(this.user.qnumber)
      .subscribe(requests => {
        // const qNumbers: string[] = [...new Set(requests.map(request => request.qnumber))];
        // this.lookupHttpService.getAll(qNumbers).subscribe(employees => {
        //   this.employees = employees;
        //   this.getTableData(requests);
        // });
        this.getTableData(requests);
      });
  }

  getTableColumns() {
    return [
      {field: 'name', headerName: this.i18nProvider.getDescription('surname_name'), filter: true, sortable: true, width: 495},
      {field: 'qnumber', headerName: this.i18nProvider.getDescription('q_number'), filter: true, sortable: true, width: 400},
      {field: 'department', headerName: this.i18nProvider.getDescription('department_code'), filter: true, sortable: true, width: 450},
      {field: 'workflowDate', headerName: this.i18nProvider.getDescription('workflow_date'), filter: true, sortable: true, width: 400, cellRenderer: (data) => {
          return formatDate(data.value, 'dd.MM.yyyy', 'de-DE');
        }},
    ];
  }

  getTableData(value: RequestsNew[]) {
    value.forEach(item => {
      //item.workflowDate = new Date(item.workflowDate).toLocaleDateString();
      const request = this.requests.get(item.qnumber) || item;
      if (!this.requests.has(item.qnumber)) {
        this.requests.set(item.qnumber, request);
      }
    });
    this.groupedRequests = Array.from(this.requests.values()).map(request => ({
      ...request,
      // department: `${this.employees.find(emp => emp.uid === request.qnumber)?.departmentNumber}`,
      // name: `${this.employees.find(emp => emp.uid === request.qnumber)?.sn}, ${this.employees.find(emp => emp.uid === request.qnumber)?.givenName}`
    }));
  }
  getSelectedLanguage(): string {
    return localStorage.getItem('selectedLanguage') ? localStorage.getItem('selectedLanguage')  : 'en';
  }
  openApprovalDashboard(openReq: OpenRequestForApprover) {
    const navigationExtras: NavigationExtras = {
      state: {
        qNumber: openReq.qnumber
      },
    };
    this.router.navigate(['/approver-dashboard'], navigationExtras);
  }

  setPaginationSize() {
    if (localStorage.getItem('ara_approver_current_request_pagination')) {
      return localStorage.getItem('ara_approver_current_request_pagination');
    }
    return 40;
  }

  onPageSizeChanged(size: any) {
    localStorage.setItem('ara_approver_current_request_pagination', size);
    this.paginationSize = size;
  }
}
