import {Component, OnInit} from '@angular/core';
import {RegionHttpService} from 'src/app/service/http/region-http.service';
import Region from 'src/app/shared/model/requests/region';
import {SweetAlertService} from 'src/app/service/feedback/SweetAlertService.service';
import {UserHttpService} from 'src/app/service/http/user-http.service';
import {BusinessRuleHttpService} from 'src/app/service/http/business-rule-http.service';
import BusinessCategory from 'src/app/shared/model/business-rules/business-category';
import {BusinessCategoryHttpService} from 'src/app/service/http/business-category-http.service';
import RegionMinified from '../../../shared/model/requests/region-min';
import {FormControl, FormGroup} from '@angular/forms';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import BusinessCategoryMinified from '../../../shared/model/business-rules/business-category-min';
import {
  CellDoubleClickedEvent,
  ColDef,
  FirstDataRenderedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  RowValueChangedEvent
} from 'ag-grid-community';
import BusinessCategoryRuleDisplayMin from '../../../shared/model/business-rules/business-category-rule-display-min';
import UserDisplay from '../../../shared/model/user/user-display';
// @ts-ignore
import germanLocale from '../../../../../node_modules/@bmw-ds/components/bmw-ag-grid-theme/locale.de.json';
import {SelectListOption} from '@bmw-ds/components';
import {I18nProvider} from '../../../service/translations/i18n.service';
import BusinessRule from "../../../shared/model/business-rules/business-rule";
import RegionPropsMinified from '../../../shared/model/requests/region-props-min';

@Component({
  selector: 'app-region-business-rule',
  templateUrl: './region-business-rule.component.html',
  styleUrls: ['./region-business-rule.component.scss']
})
export class RegionBusinessRuleComponent implements OnInit {
  selectedRegion: Region;
  loggedInUserRegions: Region[] = [];
  businessCategories: BusinessCategoryMinified[];
  showBusinessRules = false;
  regionIds: number[];
  regions: RegionPropsMinified[] = [];
  selectedRegionId = new FormControl();
  editType: 'fullRow' = 'fullRow';
  tableData: BusinessCategoryRuleDisplayMin[] = [];
  columns = this.getColumns();
  gridApi!: GridApi<UserDisplay>;
  showUpdateRule = new Map<number, boolean>();
  rowSelection: 'single' | 'multiple' = 'single';

  public rowGroupPanelShow: "always" | "onlyWhenGrouping" | "never" = "always";
  public defaultColDef: ColDef = {
    resizable: true,
  };

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    params.api.sizeColumnsToFit();
  }

  deLocaleOption: GridOptions = {
    localeTextFunc(key, defaultValue) {
      const localeDef: { [_: string]: string } = germanLocale.ds.grid;
      return defaultValue && localeDef[key] ? localeDef[key] : '';
    },
  };
  rowEvents: GridOptions = {
    onRowValueChanged: (event: RowValueChangedEvent) => this.updateRuleName(event),
    onCellDoubleClicked: (event: CellDoubleClickedEvent) => this.updateRuleStatus(event)
  }

  paginationSize: string | number;
  paginationSizes: any[] = [
    { id: 5},
    { id: 10},
    { id: 25},
    { id: 40},
    { id: 50},
    { id: 100}
  ];

  constructor(private regionHttpService: RegionHttpService, private sweetAlertService: SweetAlertService,
              private businessRulesService: BusinessRuleHttpService, private userHttpService: UserHttpService,
              private businessCategoryHttpService: BusinessCategoryHttpService, private i18nProvider: I18nProvider) {}

  ngOnInit() {
    this.initScreen();
  }

  private initScreen(){
    this.userHttpService.getCurrentUserRegions().subscribe(regions => {
        this.loggedInUserRegions = regions;
        this.regionIds = regions.map(region => +region?.id);
        this.getBusinessCategories();
        this.onRegionSelectionChange();
      },
      error => this.sweetAlertService.showComError('Unable to retrieve logged in user: ' + error));
  }


  getBusinessCategories(){
    this.businessCategoryHttpService.getMinfiedBusinessCategoriesWithRules().subscribe(successResponse => {
      this.businessCategories = successResponse;
      if (this.businessCategories) {
        this.businessCategories.sort((a, b) => a.name.localeCompare(b.name));
      }
      this.paginationSize = this.setPaginationSize();
      this.tableData = this.setTableData();
    }, (error) => {
      this.sweetAlertService.showComError('Error retrieving businessCategories: ' + JSON.stringify(error));
    });
  }

  updateBusinessRules() {
    this.regionHttpService.getRegionById(this.selectedRegionId.value).subscribe(region =>
    {
      region.businessRules = [];
      this.businessCategories.forEach(masterBusinessCategory => {
        masterBusinessCategory.businessRules.forEach(masterBusinessRule => {
          if (masterBusinessRule.selected) {
            region.businessRules.push(masterBusinessRule);
          }
        });
      });
      this.regionHttpService.updateRegion(region).subscribe(() => {
        this.showBusinessRules = false;
        this.selectedRegionId.reset();
      });
    });

  }

  onRegionSelectionChange(){
    this.selectedRegionId.valueChanges
      .pipe( distinctUntilChanged(), debounceTime(400))
      .subscribe(regionId => {
        if (regionId){
          this.selectedRegion = this.loggedInUserRegions.find(region => region?.id === +regionId);
          this.businessCategories.forEach(category => {
            category.businessRules.forEach(rule => {
              const isAvailableInRegion = rule?.regionIds.find(id => id === +regionId);
              isAvailableInRegion ? rule.selected = true : rule.selected = false;
            });
          });
          this.showBusinessRules = !!this.selectedRegion;
        }
      });
  }

  updateRuleStatus(rowObject: any) {

    if (rowObject.column.colId == 'active') {
      let businessRule = new BusinessRule();
      let data = rowObject.data;
      if (rowObject.column.colId == 'active') {
        businessRule.active = !data.active;
        rowObject.data.active = businessRule.active;
        let refreshParams = {force: true, rowNodes: [rowObject.node]};
        rowObject.api.refreshCells(refreshParams);
      }
      businessRule.id = data.business_rule_id;
      businessRule.name = data.business_rule_name;

      this.businessRulesService.updateExistingBusinessRule(businessRule).subscribe(() => {
        this.showUpdateRule.set(businessRule.id, false);
      }, (error) => {
        this.sweetAlertService.showComError('Error retrieving businessRule: ' + JSON.stringify(error));
      });
    }
  }

  updateRuleName(rowObject: any) {
    let businessRule = new BusinessRule();
    let data = rowObject.data;
    businessRule.id = data.business_rule_id;
    businessRule.name = data.business_rule_name;
    businessRule.active = data.active;

    this.businessRulesService.updateExistingBusinessRule(businessRule).subscribe(() => {
      this.showUpdateRule.set(businessRule.id, false);
    }, (error) => {
      this.sweetAlertService.showComError('Error retrieving businessRule: ' + JSON.stringify(error));
    });

  }

  setTableData(): any[] {
    const dataRows: BusinessCategoryRuleDisplayMin[] = [];
    if (this.businessCategories) {
      this.businessCategories.forEach(businessCategory => {
        businessCategory.businessRules.forEach(businessRule => {
          let displayBusinessRule = new BusinessCategoryRuleDisplayMin();
          displayBusinessRule.business_category_id = businessCategory.id;
          displayBusinessRule.business_category_name = businessCategory.name;
          displayBusinessRule.business_rule_id = businessRule.id;
          displayBusinessRule.business_rule_name = businessRule.name;
          displayBusinessRule.active = businessRule.active;
          displayBusinessRule.regions = businessRule.regionIds;
          dataRows.push(displayBusinessRule);
          displayBusinessRule = null;
        });
      });
    }
    return dataRows;
  }
  getColumns(){
    return [
      {field: 'business_category_name', headerName: this.i18nProvider.getDescription('category'), filter: true, sortable: true,
        width: 100, enableRowGroup: true, rowGroup: true, hide: true},
      {field: 'business_rule_name', headerName: this.i18nProvider.getDescription('business_rule_name'), filter: true, sortable: true,
        width: 100, editable: false, enableRowGroup: true},
      {field: 'active', headerName: this.i18nProvider.getDescription('status'), filter: true, sortable: true,
        width: 50, enableRowGroup: true, cellRenderer: (params) => { return this.setStatusCheckbox(params.data);}}
    ];

  }

  setStatusCheckbox(data) {
    if (data && data.active) {
      return '&#10003;';
    } else {
      return '&#88;';
    }
  }

  onGridReady(params: GridReadyEvent<UserDisplay>) {
    this.gridApi = params.api;
  }

  onPageSizeChanged(size: any) {
    localStorage.setItem('ara_manage_business_rules_pagination', size);
    this.paginationSize = size;
  }

  setPaginationSize() {
    if (localStorage.getItem('ara_manage_business_rules_pagination')) {
      return localStorage.getItem('ara_manage_business_rules_pagination');
    }
    return 40;
  }

  getSelectedLanguage(): string {
    return localStorage.getItem('selectedLanguage') ? localStorage.getItem('selectedLanguage')  : 'en';
  }

}
