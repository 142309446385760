<div class="container-costom">
  <div class="row">
    <div class="col-md-12 mt-5">
      <h1 class="h1-heading">
        <span class="h1-heading">{{'' | i18n: 'manage_application_rights'}}</span>
      </h1>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 mt-2 mb-2 push-right">
      <div class="form-group">
      </div>
      <div class="form-group">
        <button class="btn-nav" [ngClass]="{'disabled':showApplicationRight}" (click)="showApplicationRight=true ;
         showDisabled=true" [disabled]="showApplicationRight">{{'' | i18n: 'add_application_rights'}}<em class="fa fa-angle-right"></em></button>
      </div>
    </div>
  </div>
</div>
<div class="row table_costom">
  <div class="container-costom">
    <div style="background-color: #ffffff;padding: 10px;" *ngIf="showApplicationRight">
      <div class="row">
      <div class="col-12">
        <div class="col-12 d-flex align-items-end mt-4" >

          <div class="col-8 d-flex justify-content-start p-0">
            <ds-form-field class="col-4 pl-0">
              <label ds-label for="application_name">{{'' | i18n: 'choose_application'}}:</label>
              <ds-select
                id="application_name"
                [formControl]="applicationId"
                [labellingConfig]="{ placeholder: '' | i18n: 'choose_application'}"
                [options]="applicationSelectionList"
                required>
              </ds-select>
            </ds-form-field>

            <ds-form-field class="col-4 pl-0">
              <label ds-label for="right_name">{{'' | i18n: 'select_existing_right'}}:</label>
              <ds-select
                id="right_name"
                [formControl]="rightId"
                [labellingConfig]="{ placeholder: '' | i18n: 'select_existing_right'}"
                [options]="rightSelectionList"
                [readonly]="!applicationId.value"
                required>
              </ds-select>
            </ds-form-field>

            <ds-form-field class="col-4">
              <label ds-label for="app_right">{{'' | i18n: 'app_right_name'}}:</label>
              <input ds-input id="app_right" type="text" placeholder="{{'' | i18n: 'app_right_name'}}"
                     [readonly]="!rightId.value" [(ngModel)]="newRight.name" required/>
            </ds-form-field>


          </div>

          <div class="col-4 d-flex justify-content-end pr-0">
            <button ds-button class = "col-4 black-button" >{{'' | i18n: 'cancel'}}</button>
            <button ds-button class="col-4" [disabled]="!newRight.name" (click)="addRights()">{{'' | i18n: 'add'}}</button>
          </div>

        </div>
      </div>
      </div>
    </div>
    <div class="col-12 p-0 mt-3 mb-2" *ngIf="applications?.length > 0">
      <div class="ag-theme-alpine row">
        <div class="col-12 mt-2 mb-2 d-flex justify-content-end">
            <span class="push-right">
              Page Size:
              <select [(ngModel)]="paginationSize" (change)="onPageSizeChanged($event.target.value)" [id]="paginationSize"
                      id="page-size" class="pagination-size">
                <option [value]="size.id" *ngFor="let size of paginationSizes">{{size.id}}</option>
              </select>
            </span>
        </div>
      </div>
    </div>
    <ag-grid-angular
      style="width: 100%; height: 1200px"
      class="ag-theme-alpine"
      rowHeight="35"
      [gridOptions]="getSelectedLanguage() == 'de' ? deLocaleOption : undefined"
      [columnDefs]="columns"
      [pagination]="true"
      [suppressDragLeaveHidesColumns]="true"
      [paginationPageSize]=paginationSize
      [rowData]="tableData"
      [rowSelection]="rowSelection"
      (rowValueChanged)="rowEvents.onRowValueChanged($event)"
      (cellDoubleClicked)="rowEvents.onCellDoubleClicked($event)"
      [editType]="editType"
      [rowGroupPanelShow]="rowGroupPanelShow"
      [defaultColDef]="defaultColDef"
      (firstDataRendered)="onFirstDataRendered($event)"
      (gridReady)="onGridReady($event)">
    </ag-grid-angular>
    </div>
  </div>

<!-- Modal -->
<div class="modal fade" id="application" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true" [ngStyle]="{'display':display}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-10">
            <div class="modal-message"><strong>ARE YOU SURE YOU WANT TO DELETE THIS?</strong></div><br/>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-10 mt-2">
            <button type="" class="btn-Cancel" data-dismiss="modal">{{'' | i18n: 'no'}}</button>
            <button type="" class="btn-confirm" (click)="deleteRight()" data-dismiss="modal">YES</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
