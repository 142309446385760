import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {DsNavigationItem} from '@bmw-ds/components/ds-interfaces/navigation-bar.interface';
import {MenuServiceService} from 'src/app/service/menu-service.service';
import {I18nProvider} from 'src/app/service/translations/i18n.service';
import {UserHttpService} from '../../../service/http/user-http.service';
import User from '../../../shared/model/user/user';
import {map, Observable, tap} from 'rxjs';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  roleBasedNavigationItems: DsNavigationItem[];
  isExpanded = true;

  constructor(private menuService: MenuServiceService, private i18nProvider: I18nProvider) { }
  private navigationItems = [
    {
      id: 'item-1',
      label: this.i18nProvider.getDescription('my_requests'),
      routerLink: '/dashboard',
      icon: 'home',
    },
    {
      id: 'item-2',
      label: 'Master Admin',
      icon: 'user_favorite',
      children: [
        {routerLink: '/regions', label: this.i18nProvider.getDescription('regions')},
        {routerLink: '/regions-admins', label: this.i18nProvider.getDescription('regional_admins')}
        , {routerLink: '/business-category', label: this.i18nProvider.getDescription('business_category')}
        , {routerLink: '/business-rules', label: this.i18nProvider.getDescription('business_rule_(role)')}
        , {routerLink: '/applications', label: this.i18nProvider.getDescription('applications')}
        , {routerLink: '/application-rights-categories', label: this.i18nProvider.getDescription('application_rights_category')}
        , {routerLink: '/application-rights', label: this.i18nProvider.getDescription('application_rights')}
        , {routerLink: '/business-rule-right', label: this.i18nProvider.getDescription('business_rule_rights')}
        // , {routerLink: '/compliance-manager', label: 'Complience Manager'}
        , {routerLink: '/single-rights', label: this.i18nProvider.getDescription('single_rights')}
        // , {routerLink: '/single-rights-applications',
        //   label: this.i18nProvider.getDescription('single_rights') + ' ' +
        //     this.i18nProvider.getDescription('applications')}
        , {routerLink: '/all-users', label: 'Users'}
        , {routerLink: '/archive-dashboard', label: 'User Archive'}
        , {routerLink: '/translations', label: 'Translations'}
      ]
    },
    {
      id: 'item-3',
      label: 'Region Admin', icon: 'group',
      children: [
        {routerLink: '/regions-applications', label: this.i18nProvider.getDescription('regional_application')},
        {routerLink: '/rights-manager', label: this.i18nProvider.getDescription('rights_manager')}
        // , {routerLink: '/special-rights-approver', label: 'Special Rights Approver'}
        , {routerLink: '/locations', label: this.i18nProvider.getDescription('menu_location')}
        , {routerLink: '/region-business-rule', label: this.i18nProvider.getDescription('business_rule_(role)')}
        // , {routerLink: '/region-business-rule-rights', label: 'Business Rule Right'}
      ]
    },
    {
      id: 'item-4',
      label: this.i18nProvider.getDescription('rights_manager'),
      icon: 'user',
      children: [
        {routerLink: '/rights-manager-current', label: this.i18nProvider.getDescription('current_request_list')}
        , {routerLink: '/rights-manager-completed', label: this.i18nProvider.getDescription('completed_request_list')}
      ]
    },
    {
      id: 'item-5',
      label: this.i18nProvider.getDescription('approver') + ' (' + this.getApproverToDoCount() + ')',
      icon: 'thumb_up',
      children: [
        {routerLink: '/approver-current-request', label: this.i18nProvider.getDescription('current_request_list')}
        , {routerLink: '/approver-completed-request', label: this.i18nProvider.getDescription('completed_request_list')}
        , {routerLink: '/approver-deputy', label: this.i18nProvider.getDescription('deputy')}
        , {routerLink: '/approver-my-team', label: this.i18nProvider.getDescription('my_team')}
      ]
    },
    // {
    //   id: 'item-6',
    //   label: 'compliance', icon: 'file_checked',
    //   children: [
    //     {routerLink: '/compliance-current-request', label: 'Current Requests'}
    //     , {routerLink: '', label: 'Completed Requests'}
    //     , {routerLink: '', label: 'Deputy'}
    //   ]
    // },
    // {
    //   id: 'item-7',
    //   label: 'Special Rights Approver', icon: 'user_check',
    //   children: [
    //     {routerLink: '', label: 'Current Requests'}
    //     , {routerLink: '', label: 'Completed Requests'}
    //     , {routerLink: '', label: 'Deputy'}
    //   ]
    // },
    {
      id: 'item-8',
        label: this.i18nProvider.getDescription('my_archive'),
      routerLink: '/my-archive',
      icon: 'archive',
    },
  ];

  ngOnInit() {
    this.roleBasedNavigationItems = this.menuService.getRoleBasedUserMenuItems(this.navigationItems);
  }

  getApproverToDoCount() {
      if (localStorage.getItem('ara_approver_todo_count')) {
        return localStorage.getItem('ara_approver_todo_count');
      } else {
        return 0;
      }
  }
}
