<div class="container-costom">
  <div class="row">
    <div class="col-md-6 mt-5">
      <h1 class="h1-heading">
        <span class="orange">{{'' | i18n: 'manage_business_rules'}}</span>
      </h1>
    </div>
  </div>
  <div class="row">
    <div class="form-group c-label col-8 app_select mt-3 d-flex flex-column" >
      <label ds-label >{{'' | i18n: 'current_region'}}:</label>

      <select [formControl]="selectedRegionId">
        <option selected disabled>{{'' | i18n: 'select_region'}}</option>
        <option *ngFor="let region of  loggedInUserRegions"  [value]="region?.id">{{region?.name}}</option>
      </select>
    </div>
    <div class="col-4 mb-2 push-right">
      <div class="form-group">
        <button class="btn-orange-costom" data-toggle="modal" data-target="#rule">{{'' | i18n: 'update_business_rules'}}<em class="fa fa-angle-right"></em></button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="showBusinessRules">
  <ag-grid-angular
    style="width: 100%; height: 1200px"
    class="ag-theme-alpine"
    rowHeight="35"
    [gridOptions]="getSelectedLanguage() == 'de' ? deLocaleOption : undefined"
    [columnDefs]="columns"
    [pagination]="true"
    [suppressDragLeaveHidesColumns]="true"
    [paginationPageSize]=paginationSize
    [rowData]="tableData"
    [rowSelection]="rowSelection"
    (rowValueChanged)="rowEvents.onRowValueChanged($event)"
    (cellDoubleClicked)="rowEvents.onCellDoubleClicked($event)"
    [editType]="editType"
    [rowGroupPanelShow]="rowGroupPanelShow"
    [defaultColDef]="defaultColDef"
    (firstDataRendered)="onFirstDataRendered($event)"
    (gridReady)="onGridReady($event)">
  </ag-grid-angular>
</div>

<!--<div class="row table_costom" *ngIf="showBusinessRules">-->
<!--  <div class="container-costom row">-->
<!--    <div  class="col-md-6 col-lg-6 region-rules" *ngFor="let businessCategory of businessCategories, index as i">-->
<!--      <div id="accordion-request">-->
<!--        <div class="panel">-->
<!--          <div class="panel-heading">-->
<!--            <h4 class="">-->
<!--              <a href="#application-{{i}}" class="accordion-toggle collapsed" data-toggle="collapse"-->
<!--                 data-parent="#accordion-request"-->
<!--                 aria-expanded="false">-->
<!--                <div class="row header-background">-->
<!--                  <div class="col-lg-12 accordion-data">-->
<!--                    <div class="row mb-3">-->
<!--                      <div class="col-lg-9">-->
<!--                        {{businessCategory.name}}-->
<!--                      </div>-->
<!--                      <div class="col-lg-3">-->
<!--                        {{'' | i18n: 'active'}}-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </a>-->
<!--            </h4>-->
<!--          </div>-->
<!--          <div id="application-{{i}}" class="panel-collapse collapse">-->
<!--              <div class="row" *ngFor="let businessRule of businessCategory.businessRules">-->
<!--                <div class="col-md-10 mb-2">-->
<!--                  {{businessRule.name}}-->
<!--                </div>-->
<!--                <div class="col-md-2 mb-2">-->
<!--                  <label class="container">-->
<!--                    <input type="checkbox" [(ngModel)]="businessRule.selected">-->
<!--                    <span  class="checkmark"></span>-->
<!--                  </label>-->
<!--                </div>-->
<!--              </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
<!-- Modal -->
<div class="modal fade" id="rule" role="dialog" aria-labelledby="exampleModalLabel" >
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-10">
            <div class="modal-message"><strong>{{'' | i18n: 'are_you_sure_you_want_to_update_these_business_rules'}}?</strong></div><br/>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-10 mt-2">
            <button class="btn-Cancel" data-dismiss="modal" type="" style="color: black !important;">{{'' | i18n: 'no'}}</button>
            <button (click)="updateBusinessRules()" class="btn-confirm" data-dismiss="modal" type="">{{'' | i18n: 'yes'}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
