<div class="container-costom">
  <div class="row mt-5 mb-4">
    <div class="col-md-12 mt-4 mb-4">
      <h1 class="h1-heading">
        <span class="blue">{{'' | i18n: 'business_rule_rights'}}</span>
      </h1>
    </div>
    <form ds-form class="col-12 p-0 d-flex align-items-end" [formGroup]="selectionForm">

      <div class="col-8 d-flex p-0">
        <ds-form-field class="col-4">
          <label>{{'' | i18n: 'select_category'}}:</label>
          <ds-select
            formControlName="businessCategory"
            [labellingConfig]="{ placeholder: 'Select Business Category' }"
            [options]="businessCategoryList">
          </ds-select>
        </ds-form-field>

        <ds-form-field class="col-4">
          <label>{{'' | i18n: 'select_business_rule'}}:</label>
          <ds-select
            formControlName="businessRule"
            [labellingConfig]="{ placeholder: '' | i18n: 'select_business_rule' }"
            [options]="businessRuleList"
            [readonly]="!selectionForm.controls.businessCategory.value">
          </ds-select>
        </ds-form-field>

        <ds-form-field class="col-4">
          <label>{{'' | i18n: 'select_application'}}:</label>
          <ds-select
            formControlName="application"
            [labellingConfig]="{ placeholder: '' | i18n: 'select_application' }"
            [options]="applicationList"
            [readonly]="!selectionForm.controls.businessRule.value">
          </ds-select>
        </ds-form-field>

      </div>
      <div class="col-4 d-flex justify-content-end p-0">
        <button class="col-6 d-flex flex-row-reverse justify-content-between" ds-button icon="arrow_right_small"
                [disabled]="!selectionForm.controls.application.value"
                (click)="clickSaveRights()" >
          {{'' | i18n: 'save_rights'}}
        </button>
      </div>

    </form>
    <div class="col-md-5 push-right">
      <div class="form-group" style="margin-right: -13px;">
      </div>
    </div>
  </div>
</div>
<div class="row table_costom">
  <div class="container-costom">
    <div class="row">
      <div class="col-md-7">
        <div class="col-12 p-0 mt-3 mb-2"  *ngIf="tableData.length > 0">
          <div class="ag-theme-alpine row">
            <div class="col-6 mt-2 mb-2 d-flex">
              <h3>CONFIGURATION</h3>
            </div>
          </div>
          <div *ngIf="selectionForm.controls.application.value">
            <span class = "sub-app">{{selectedApplication?.name}}</span><br />
          </div>
        </div>
        <div *ngIf="selectionForm.controls.application.value">
          <ag-grid-angular
            style="width: 100%; height: 1200px"
            class="ag-theme-alpine"
            rowHeight="35"
            [gridOptions]="getSelectedLanguage() == 'de' ? deLocaleOption : undefined"
            [columnDefs]="columns"
            [pagination]="true"
            [suppressDragLeaveHidesColumns]="true"
            [paginationPageSize]=paginationSize
            [rowData]="tableData"
            [rowSelection]="rowSelection"
            (rowValueChanged)="rowEvents.onRowValueChanged($event)"
            (cellDoubleClicked)="rowEvents.onCellDoubleClicked($event)"
            [editType]="editType"
            [rowGroupPanelShow]="rowGroupPanelShow"
            [defaultColDef]="defaultColDef"
            (firstDataRendered)="onFirstDataRendered($event)"
            (gridReady)="onGridReady($event)">
          </ag-grid-angular>
        </div>
      </div>
      <div class="col-md-5 mt-4 mb-4 OverView" *ngIf="selectionForm.controls.businessRule.value">
        <h3>{{'' | i18n: 'overview'}}</h3>
        <div class = "Preview-content">
          <div>
            <span class = "sub-app">{{selectedBusinessRule?.name}}</span><br />
          </div>
          <div *ngIf="selectionForm.controls.businessRule.value">
            <ag-grid-angular
              style="width: 100%; height: 1200px"
              class="ag-theme-alpine"
              rowHeight="35"
              [gridOptions]="getSelectedLanguage() == 'de' ? deLocaleOption : undefined"
              [columnDefs]="columnsOverview"
              [pagination]="true"
              [suppressDragLeaveHidesColumns]="true"
              [paginationPageSize]=paginationSize
              [rowData]="tableDataOverview"
              [rowSelection]="rowSelection"
              [editType]="editType"
              [rowGroupPanelShow]="rowGroupPanelShow"
              [defaultColDef]="defaultColDef"
              (firstDataRendered)="onFirstDataRendered($event)"
              (gridReady)="onGridReady($event)">
            </ag-grid-angular>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--<div class="row table_costom">-->
<!--  <div class="container-costom">-->
<!--    <div class="row">-->
<!--      <div class="col-md-7">-->
<!--        <div class="" *ngIf="selectionForm.controls.application.value">-->
<!--          <div class="row">-->
<!--            <div class="col-md-12 mt-4">-->
<!--              <div id="accordion-request">-->
<!--                <div class="panel" *ngFor="let applicationRightsCategory of selectedApplication?.applicationRightsCategories, index as i">-->
<!--                  <div class="panel-heading" *ngIf="isApplicationCategoryRightsAvailable(applicationRightsCategory)">-->
<!--                    <h4 class="panel-title">-->
<!--                      <a href="#application-{{i}}" class="accordion-toggle collapsed" data-toggle="collapse"-->
<!--                         data-parent="#accordion-request"-->
<!--                         aria-expanded="false">{{applicationRightsCategory.name}}-->
<!--                      </a>-->
<!--                    </h4>-->
<!--                  </div>-->
<!--                  <div id="application-{{i}}" class="panel-collapse collapse">-->
<!--                    <div class="row">-->
<!--                      <div class="col-md-12">-->
<!--                        <table class="table ">-->
<!--                          <tbody>-->
<!--                          <tr *ngFor="let right of applicationRightsCategory.rights">-->
<!--                            <td *ngIf="right.active">{{right.name}}-->
<!--                              <label class="container-checkbox">-->
<!--                                <input type="checkbox"  [checked]="checkedValue(right)"-->
<!--                                       (change)="toggleRequestedRights(selectedApplication, applicationRightsCategory, right)">-->
<!--                                <span class="checkmark-brr"></span>-->
<!--                              </label>-->
<!--                            </td>-->
<!--                          </tr>-->
<!--                          </tbody>-->
<!--                        </table>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="col-md-5 mt-4 mb-4 OverView" *ngIf="selectionForm.controls.businessRule.value">-->
<!--        <h3>{{'' | i18n: 'overview'}}</h3>-->
<!--        <div class = "Preview-content">-->
<!--          <div>-->
<!--            <span class = "sub-app">{{selectedBusinessRule?.name}}</span><br />-->
<!--          </div>-->
<!--          <div *ngFor="let app of overview">-->
<!--            <span class = "sub-app2" ><u>{{app.name}}</u></span><br />-->
<!--            <div *ngFor="let appCat of app.applicationRightsCategories">-->
<!--              <span class = "sub-app2"><b>{{appCat.name}}</b></span>-->
<!--              <div class = "sub-app4" *ngFor="let right of appCat.rights">-->
<!--                <span  *ngIf="!isNewRight(right)">{{right.name}}</span>-->
<!--                <span class = "new" *ngIf="isNewRight(right)">{{right.name}}</span>-->
<!--              </div>-->

<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
